import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import { makeStyles, TextField } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import axios from "axios";
import { changeSelectedArea, changeSelectedAreaName } from "../../../redux/actions/actions";
import slugify from "react-slugify";
import { capitalizeFirstLetter } from "../../../../helpers/base";
import I18n from "../../../../i18n-js/index.js.erb";
import HotelBreakLoader from "../../components/loaders/hotelBreakLoader";
import { styled } from "@mui/material/styles";
import AreaSelectortextfieldStyled from "../../stylesUI/AreaSelectortextfieldStyled";

function SelectArea(props) {
  const dispatch = useDispatch();
  const csrfToken = useSelector((state) => state.csrfToken);
  const currentLocale = useSelector((state) => state.currentLocale);

  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;

  const [areas, setAreas] = useState(false);
  const [topAreas, setTopAreas] = useState(false);
  const [otherAreas, setOtherAreas] = useState(false);
  const [searchArea, setSearchArea] = useState("");

  const onSearchArea = (event) => {
    setSearchArea(event.target.value);
  };

  const onOldPageAreaSelection = (area) => {
    window.location = `/${currentLocale}/${slugify(area)}`
  };

  const onAreaSelect = (area) => {
    axios
      .post("/api/main/areas/cookie.json", { area: slugify(area) })
      .then((response) => {
        if (response.data.status === true && area !== null) {
          dispatch(changeSelectedArea(slugify(area)));
          dispatch(changeSelectedAreaName(area));
          onOldPageAreaSelection(area);
        }
      });
    props.handleClose();
  };

  const searchFilter = () => {
    return areas.filter((str) => {
      return str.toLowerCase().indexOf(searchArea.toLowerCase()) >= 0;
    });
  };

  useEffect(() => {
    axios
      .get(`/api/main/areas/index.json?locale=${currentLocale}`)
      .then((response) => {
        setAreas(response.data.areas);
        setTopAreas(response.data.top_areas);
        setOtherAreas(response.data.other_areas);
      });
  }, []);

  var loadedAreas = topAreas && otherAreas;

  return (
    <React.Fragment>
      {loadedAreas && (
        <DialogTitle id="form-dialog-title">
          {I18n.t("select_city")}
        </DialogTitle>
      )}
      <DialogContent>
        {!loadedAreas && <HotelBreakLoader innerStyles={{margin: "auto"}}/>}
        {loadedAreas && (
          <AreaSelectortextfieldStyled onSearchArea={onSearchArea} />
        )}

        {loadedAreas && <hr></hr>}

        <List component="nav" aria-label="secondary mailbox folders">
          {searchArea.length == 0 && loadedAreas && (
            <DialogContentText>{I18n.t("top_cities")}</DialogContentText>
          )}
          {searchArea.length == 0 &&
            loadedAreas &&
            topAreas.map((area) => {
              return (
                <ListItem onClick={() => onAreaSelect(area)} button>
                  <ListItemText primary={capitalizeFirstLetter(area)} />
                </ListItem>
              );
            })}
          <br></br>
          {searchArea.length == 0 && loadedAreas && (
            <DialogContentText>{I18n.t("other_cities")}</DialogContentText>
          )}
          {searchArea.length == 0 &&
            loadedAreas &&
            otherAreas.map((area) => {
              return (
                <ListItem onClick={() => onAreaSelect(area)} button>
                  <ListItemText primary={capitalizeFirstLetter(area)} />
                </ListItem>
              );
            })}

          {searchArea.length != 0 &&
            searchFilter().length != 0 &&
            areas &&
            searchFilter().map((area) => {
              return (
                <ListItem onClick={() => onAreaSelect(area)} button>
                  <ListItemText primary={capitalizeFirstLetter(area)} />
                </ListItem>
              );
            })}

          {searchArea.length != 0 &&
            searchFilter().length == 0 &&
            I18n.t("not_yet_available") + " :("}
        </List>
      </DialogContent>

      {topAreas && (
        <DialogActions>
          <Button
            onClick={props.handleClose}
            color="primary"
            className="btn btn-primary"
          >
            {I18n.t("gift_voucher_modal_close")}
          </Button>
        </DialogActions>
      )}
    </React.Fragment>
  );
}

export default SelectArea;
