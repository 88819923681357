import React from "react";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import {
  PaymentData,
  changeLocaleReducer,
  currentUserReducer,
  configCsrfTokenReducer,
  desktopUserLoginFormStatus,
  clientSecret,
} from "./redux/reducers/reducers";
import Main from "./paymentfunnel/Main";
import { CookiesProvider } from "react-cookie";
 

const store = configureStore({
  reducer: {
    PaymentData: PaymentData,
    currentLocale: changeLocaleReducer,
    currentUser: currentUserReducer,
    csrfToken: configCsrfTokenReducer,
    desktopUserLoginFormStatus: desktopUserLoginFormStatus,
    clientSecret: clientSecret,
  },
});

function PayApp(props) {
  return (
    <Provider store={store}>
      <React.Fragment>
        <CookiesProvider>
          <Main {...props} />
        </CookiesProvider>
      </React.Fragment>
    </Provider>
  );
}

export default PayApp;
