import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Header from "./Header";
import HeaderMobile from "./HeaderMobile";
import { makeStyles } from "@material-ui/core/styles";
import { capitalizeFirstLetter } from "../../../helpers/base";
import { createMedia } from "@artsy/fresnel";
import I18n from "../../../i18n-js/index.js.erb";
import slugify from "react-slugify";
import RedeemVoucherForm from "./dialogs/RedeemVoucherForm";
import { useSelector, useDispatch } from "react-redux";
import UserLoginWrap from "../components/modals/UserLogin/UserLoginWrap";
import { setDesktopUserLoginFormStatus } from "../../redux/actions/actions";
import clsx from "clsx";

function RedeemVoucher(props) {
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    slidingPane: {
      marginTop: "250px",
      paddingTop: "5px",
      borderTopLeftRadius: "20px",
      borderTopRightRadius: "20px",
      backgroundColor: "white",
      width: "100%",
      height: "fit-content",
      position: "relative",
      boxShadow: "0 -2px 4px rgba(0,0,0,0.25)",
      zIndex: "1",
    },
    root: {
      backgroundColor: "#F5F5F5",
    },
    filtered: {
      filter: 'blur(5px)',
    }
  }));

  const classes = useStyles();

  const currentLocale = useSelector(
    (state) => state.currentLocale
  );

  I18n.locale = currentLocale

  const currentUser = useSelector(
    (state) => state.currentUser
  );

  const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      sm: 0,
      md: 768,
      lg: 1024,
      xl: 1192,
    },
  });

  useEffect(() => {
    !currentUser.id &&
      dispatch(
        setDesktopUserLoginFormStatus({
          open: true,
          selectRegister: true,
          notClosable: true
        })
      );
      document.title = `${I18n.t('gift_voucher_redeem_title')} | hotelbreak`
  }, [])

  return (
    <div className={clsx(classes.root, !currentUser.id && classes.filtered)}>
      <MediaContextProvider>
        <Media at="sm">
          <HeaderMobile text={I18n.t('gift_voucher_redeem_title')} redeem={true} />
          <div className={classes.slidingPane}>
          <RedeemVoucherForm></RedeemVoucherForm>
          </div>
        </Media>
        <Media at="md">
          <HeaderMobile text={I18n.t('gift_voucher_redeem_title')} redeem={true} />
          <div className={classes.slidingPane}>
          <RedeemVoucherForm></RedeemVoucherForm>
          </div>
        </Media>
        <Media greaterThanOrEqual="lg">
          <Header text={I18n.t('gift_voucher_redeem_title')} redeem={true} />
          <div className={classes.slidingPane}>
          <RedeemVoucherForm></RedeemVoucherForm>
          </div>
        </Media>
      </MediaContextProvider>

      <UserLoginWrap reason={I18n.t('gift_voucher_redeem_title').toLowerCase()}/>
    </div>
  );
}

export default RedeemVoucher;
