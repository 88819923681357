import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Moment from "moment";
import { to_eur } from "../../../helpers/base";
import { makeStyles } from "@material-ui/core/styles";
import Emoji from "a11y-react-emoji";
import axios from "axios";
import I18n from "i18n-js";

function ProductOnDemandForm(props) {
  const area = props.area;
  const hotel = props.hotel;
  const product = props.product;

  const csrfToken = useSelector((state) => state.csrfToken);

  const [selectedPax, setSelectedPax] = useState(1);
  const [selectedPaxKids, setSelectedPaxKids] = useState(0);

  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;

  Moment.locale("es");

  const useStyles = makeStyles((theme) => ({
    primaryColor: {
      backgroundColor: "#F7EB67",
      color: "black !important",
    },
    paxSelector: {
      fontSize: "32px",
      fontWeight: "600 !important",
    },
    price: {
      fontSize: "30px",
      fontWeight: "800 !important",
    },
    totalText: {
      lineHeight: "43px",
    },
    fullBtn: {
      display: "block !important",
      width: "100%",
    },
  }));

  const classes = useStyles();

  const handleRemovePax = () => {
    if (selectedPax > 1) {
      setSelectedPax(selectedPax - 1);
    }
  };

  const handleAddPax = () => {
    if (
      selectedPax + selectedPaxKids <
      props.experience.attributes.productTickets.ticket_number
    ) {
      setSelectedPax(selectedPax + 1);
    }
  };

  const handleRemovePaxKids = () => {
    if (selectedPaxKids > 0) {
      setSelectedPaxKids(selectedPaxKids - 1);
    }
  };

  const handleAddPaxKids = () => {
    if (
      selectedPax + selectedPaxKids <
      props.experience.attributes.productTickets.ticket_number
    ) {
      setSelectedPaxKids(selectedPaxKids + 1);
    }
  };

  const handleRedirectToGiftVoucherPage = () => {
    axios
      .post("/api/main/gift_vouchers/new.json", {
        gift_voucher: {
          product_slug: product,
          hotel_slug: hotel,
          adults: selectedPax,
          kids: selectedPaxKids,
          locale: "es",
        },
      })
      .then((response) => {
        location.href = location.origin + response.data.redirect_to_gift_voucher;
      });
  };


  return (
    <>
      <br />
      <p>
        {I18n.t('adults')} (
        {to_eur(
          props.experience.attributes.pvp *
            Math.ceil(
              selectedPax / props.experience.attributes.productTickets.max_guest
            )
        )}
        €)
      </p>
      <div className="row">
        <div className="col-xs-4 text-center">
          <Fab
            className={classes.primaryColor}
            aria-label="remove"
            onClick={handleRemovePax}
          >
            <RemoveIcon />
          </Fab>
        </div>
        <div className="col-xs-4 text-center">
          <p className={classes.paxSelector}>{selectedPax}</p>
        </div>
        <div className="col-xs-4 text-center">
          <Fab
            className={classes.primaryColor}
            aria-label="add"
            onClick={handleAddPax}
          >
            <AddIcon />
          </Fab>
        </div>
      </div>

      {props.experience.attributes.productTickets.accept_kids && (
        <React.Fragment>
          <p>
          {I18n.t('kids')} (
            {to_eur(
              props.experience.attributes.pvpKids *
                Math.ceil(
                  selectedPax /
                    props.experience.attributes.productTickets.max_guest
                )
            )}
            €)
          </p>
          <div className="row">
            <div className="col-xs-4 text-center">
              <Fab
                className={classes.primaryColor}
                aria-label="remove"
                onClick={handleRemovePaxKids}
              >
                <RemoveIcon />
              </Fab>
            </div>
            <div className="col-xs-4 text-center">
              <p className={classes.paxSelector}>{selectedPaxKids}</p>
            </div>
            <div className="col-xs-4 text-center">
              <Fab
                className={classes.primaryColor}
                aria-label="add"
                onClick={handleAddPaxKids}
              >
                <AddIcon />
              </Fab>
            </div>
          </div>
        </React.Fragment>
      )}

      <div className="row">
        <div className="col-xs-12">
          <hr />
        </div>
        <div className="col-xs-6">
          <p className={classes.totalText}>Total</p>
        </div>
        <div className="col-xs-6 text-right">
          <p className={classes.price}>
            {to_eur(
              props.experience.attributes.pvp *
                Math.ceil(
                  selectedPax /
                    props.experience.attributes.productTickets.max_guest
                )
            )}
            €
          </p>
        </div>
      </div>
      <button className={"btn btn-primary " + classes.fullBtn}
              onClick={() => handleRedirectToGiftVoucherPage()}
      >
        {I18n.t('book_as_gift')}
      </button>
      <br />
    </>
  );
}

export default ProductOnDemandForm;
