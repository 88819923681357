import React, { useEffect } from "react";
import PaymentStep from "./PaymentStep";
import I18n from "../../i18n-js/index.js.erb";
import {
  setPaymentData,
  changeLocale,
  setCurrentUser,
  addCSRFToken,
  setClientSecret,
} from "../redux/actions/actions";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const StyledCircularProgress = withStyles({
  root: {
    marginTop: "3rem",
  },
})(CircularProgress);

const reservation_code = new URLSearchParams(window.location.search).get(
  "reservation_code"
);

const setApiKey = () => {
  if (process.env.RAILS_ENV !== "production") {
    return "pk_test_t6NYOy0tevDzxRtvhhdUSHiU";
  } else {
    return process.env.PUBLISHABLE_KEY;
  }
};

const stripePromise = loadStripe(setApiKey());

function PaymentWrap(props) {
  const dispatch = useDispatch();
  const FunnelData = useSelector((state) => state.PaymentData);
  const currentLocale = useSelector((state) => state.currentLocale);
  const currentUser = useSelector((state) => state.currentUser);
  const clientSecret = useSelector((state) => state.clientSecret);

  I18n.locale = currentLocale || props.locale;

  const getClientSecret = (getFunnelData, iteration) => {
    if (iteration > 9) {
      window.location.reload();
    } else {
      axios
        .post(`/api/main/funnel/intents`, {
          reservation_code: reservation_code,
        })
        .then((response) => {
          dispatch(
            setPaymentData({
              ...getFunnelData,
              intent: response.data.intent,
              dispatch_id: "pw53",
            })
          );
          dispatch(setClientSecret(response.data.client_secret));
        })
        .catch(function (error) {
          console.log(error);
          setTimeout(function () {
            getClientSecret(getFunnelData, iteration + 1);
          }, 3000);
        });
    }
  };

  useEffect(() => {
    dispatch(changeLocale(props.locale));
    dispatch(addCSRFToken(props.csrf_token));
    if (currentUser.loggedIn === false) {
      dispatch(setCurrentUser(props.current_user.data.attributes));
    }

    axios
      .get(`../../api/main/funnel/summaries/${reservation_code}`)
      .then((response) => {
        if (response.status === 200) {
          var getFunnelData = {
            ...FunnelData,
            ProductData: response.data.product.data.attributes,
            ReservationData: response.data.reservation.data.attributes,
            PaymentData: response.data.payment_data,
            wallet: response.data.wallet,
            is_referral_active: response.data.is_referral_active,
            is_hotelbreak_direct: response.data.is_hotelbreak_direct,
            disable_gift_voucher: response.data.disable_gift_voucher,
            firstLoad: true,
            PaymentOption: response.data.payment_option,
            scheduled: response.data.scheduled,
            dispatch_id: "pw81",
          };
          dispatch(setPaymentData(getFunnelData));
          getClientSecret(getFunnelData, 1);
        }
      })
      .catch(function (error) { });
  }, []);
  

  return (
    <React.Fragment>
      <br />
      <div className="col-md-7">
        {clientSecret === "" && <StyledCircularProgress />}
        {clientSecret !== "" && (
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret: clientSecret,
              paymentMethodCreation: 'manual',
              locale: currentLocale,
              appearance: {
                theme: 'stripe',
                variables: {
                  colorPrimary: '#30313d',
                  fontFamily: 'Tobias, Times New Roman, serif',
                  borderRadius: "4px",
                  colorText: '#30313d',
                  colorBackground: '#fff',
                }
              }
            }}
          >
            <PaymentStep {...props} />
          </Elements>
        )}
      </div>
    </React.Fragment>
  );
}

export default PaymentWrap;
