import React from "react";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import I18n from "../../../../i18n-js/index.js.erb";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { setDesktopUserLoginFormStatus } from "../../../redux/actions/actions";
import { changeHotelData } from "../../../redux/actions/actions";

function SubscriptionForm(props) {
  const [subscriptionStatus, setSubscriptionStatus] = useState(false);
  const hotelUsedData = useSelector((state) => state.hotelUsedData);
  const currentUser = useSelector((state) => state.currentUser);
  const currentLocale = useSelector((state) => state.currentLocale);
  const dispatch = useDispatch();
  const bookable = props.bookable;

  const handleUserRegisterOpen = () => {
    dispatch(
      setDesktopUserLoginFormStatus({
        open: true,
        signin: false,
        register: false,
        selectRegister: true,
        selectSignin: false,
        source: "subscription",
      })
    );
  };

  const handleChangeHotelData = (data) => {
    dispatch(changeHotelData(data));
  };

  const useStyles = makeStyles((theme) => ({}));

  const classes = useStyles();

  const handleSubscriptionCreation = () => {
    axios
      .get("/api/main/subscriptions/create.json", {
        params: { slug: props.hotel },
      })
      .then((response) => {
        setSubscriptionStatus(response.data.status);
      });
  };

  const handleSubscriptionCancelation = () => {
    axios
      .get("/api/main/subscriptions/delete.json", {
        params: { slug: props.hotel },
      })
      .then((response) => {
        response.data.status && setSubscriptionStatus("canceled");
      });
  };

  useEffect(() => {
    if (currentUser.id) {
      axios
        .get("/api/main/subscriptions/show.json", {
          params: { slug: props.hotel },
        })
        .then((response) => {
          setSubscriptionStatus(response.data.status);
        })
        .catch((error) => {
          console.log("error: ", error);
          setSubscriptionStatus(false);
        });
    }
    !hotelUsedData.slug &&
      axios
        .get("/app/hotels/show.json", {
          params: { slug: props.hotel, locale: currentLocale },
        })
        .then((response) => {
          handleChangeHotelData(response.data);
        });
  }, [currentUser.id]);

  return props.full ? (
    <>
      <div className={"col-md-12 no-padding bite"}>
        {!bookable &&
        (!subscriptionStatus || subscriptionStatus === "canceled") ? (
          <React.Fragment>
            <br />
            <div className="col-md-8">
              <h3>
                {subscriptionStatus === "canceled" ? (
                  <>
                    {I18n.t("ok_cancel_subscription", {
                      hotel:
                        hotelUsedData.hotel && hotelUsedData.hotel.listing_name,
                    })}
                  </>
                ) : (
                  <>
                    {I18n.t("not_yet_available", {
                      hotel:
                        hotelUsedData.hotel && hotelUsedData.hotel.listing_name,
                    })}
                  </>
                )}
              </h3>
            </div>
            <br />
            <div className="col-md-4  text-right">
              <button
                onClick={() =>
                  currentUser.id
                    ? handleSubscriptionCreation()
                    : handleUserRegisterOpen()
                }
                className={clsx("btn btn-primary")}
              >
                {I18n.t("get_alerted_button")}
              </button>
            </div>
            <div className="col-md-3 text-center">
              <svg
                width="97"
                height="24"
                viewBox="0 0 97 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M93.3536 0.646447C93.1583 0.451184 92.8417 0.451184 92.6464 0.646447L89.4645 3.82843C89.2692 4.02369 89.2692 4.34027 89.4645 4.53553C89.6597 4.7308 89.9763 4.7308 90.1716 4.53553L93 1.70711L95.8284 4.53553C96.0237 4.7308 96.3403 4.7308 96.5355 4.53553C96.7308 4.34027 96.7308 4.02369 96.5355 3.82843L93.3536 0.646447ZM0.889027 17.7118C23.256 22.803 46.3192 24.8582 63.8148 22.5711C72.559 21.428 79.9587 19.1945 85.1856 15.6738C90.4338 12.1387 93.5 7.29487 93.5 1H92.5C92.5 6.90578 89.6495 11.4612 84.6269 14.8444C79.5829 18.242 72.3576 20.4458 63.6852 21.5795C46.3474 23.846 23.4107 21.8127 1.11097 16.7367L0.889027 17.7118Z"
                  fill="black"
                />
              </svg>
            </div>

            <div className="col-md-12">
              <br />
              <br />
            </div>
          </React.Fragment>
        ) : (
          hotelUsedData.hotel &&
          subscriptionStatus && (
            <React.Fragment>
              <br />
              <div className="col-md-12">
                <h3>
                  {I18n.t("registered_to_get_alerted_about", {
                    hotel: hotelUsedData.hotel.listing_name,
                  })}
                </h3>
              </div>
              <br />

              <div className="col-md-12">
                <br />
                <br />
              </div>
            </React.Fragment>
          )
        )}
      </div>
    </>
  ) : (
    <>
      <div className="col-md-12">
        {!bookable &&
        (!subscriptionStatus || subscriptionStatus === "canceled") ? (
          <React.Fragment>
            <br />
            <div className="col-md-12">
              <h3>
                {subscriptionStatus === "canceled" ? (
                  <>
                    {I18n.t("ok_cancel_subscription", {
                      hotel:
                        hotelUsedData.hotel && hotelUsedData.hotel.listing_name,
                    })}
                  </>
                ) : (
                  <>
                    {I18n.t("not_yet_available", {
                      hotel:
                        hotelUsedData.hotel && hotelUsedData.hotel.listing_name,
                    })}
                  </>
                )}
              </h3>
            </div>
            <br />
            <div className="col-md-12  text-right">
              <button
                onClick={() =>
                  currentUser.id
                    ? handleSubscriptionCreation()
                    : handleUserRegisterOpen()
                }
                className={clsx("btn btn-primary")}
                style={{ width: "100%" }}
              >
                {I18n.t("get_alerted_button")}
              </button>
            </div>

            <div className="col-md-12">
              <br />
              <br />
            </div>
          </React.Fragment>
        ) : (
          hotelUsedData.hotel &&
          subscriptionStatus && (
            <React.Fragment>
              <br />
              <div className="col-md-12">
                <h3>
                  {I18n.t("registered_to_get_alerted_about", {
                    hotel:
                      hotelUsedData.hotel && hotelUsedData.hotel.listing_name,
                  })}
                </h3>
              </div>
              <br />

              <div className="col-md-12">
                <br />
                <br />
              </div>
            </React.Fragment>
          )
        )}
      </div>
      <div className="col-md-12"></div>
    </>
  );
}

export default SubscriptionForm;
