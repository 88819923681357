import React from "react";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import {
  configAreaReducer,
  changeLocaleReducer,
  configCsrfTokenReducer,
  changeHotelDataReducer,
  currentUserReducer,
  currentAvailabilities,
  desktopUserLoginFormStatus,
  areaHomeProductsReducer,
  stepsVoucherCreationParams,
  clientSecret,
  configAreaNameReducer,
  areaHomeHotelCategoriesReducer
} from "./redux/reducers/reducers";
import AllReviewsList from "./reviews/AllReviewsList";

const store = configureStore({
  reducer: {
    area: configAreaReducer,
    areaName: configAreaNameReducer,
    currentLocale: changeLocaleReducer,
    csrfToken: configCsrfTokenReducer,
    hotelUsedData: changeHotelDataReducer,
    currentUser: currentUserReducer,
    currentAvailabilities: currentAvailabilities,
    desktopUserLoginFormStatus: desktopUserLoginFormStatus,
    areaHomeProducts: areaHomeProductsReducer,
    stepsVoucherCreationParams: stepsVoucherCreationParams,
    clientSecret: clientSecret,
    areaHomeHotelCategories: areaHomeHotelCategoriesReducer,
  },
});

function reviewsApp(props) {
  return (
    <Provider store={store}>
      <AllReviewsList {...props} />
    </Provider>
  );
}

export default reviewsApp;
