import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import I18n from "../../../../../i18n-js/index.js.erb";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Label, Input, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  setDesktopUserLoginFormStatus,
  setCurrentUser,
} from "../../../../redux/actions/actions";
import * as yup from 'yup';
import { getSystemErrorMap } from "util";



function UserLogin() {
  const dispatch = useDispatch();

  const currentLocale = useSelector((state) => state.currentLocale)

  const schema = yup.object({
    email: yup.string().email(I18n.t('reservation_email_format')).required(I18n.t('is_required')),
    password: yup.string().required(I18n.t('is_required')),
  })

  const desktopUserLoginFormStatus = useSelector(
    (state) => state.desktopUserLoginFormStatus
  );
  const hotelUsedData = useSelector((state) => state.hotelUsedData);

  const { register, handleSubmit, setError, formState:{ errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data) => {
    axios
      .post("/api/main/registrations/create", {...data, subscription: desktopUserLoginFormStatus.subscription})
      .then((response) => {
        if (response.data.status) {
          dispatch(setCurrentUser({
            ...response.data.current_user,
            wallet: response.data.wallet
          }));
          if (desktopUserLoginFormStatus.source === 'subscription') {
            axios
              .get("/api/main/subscriptions/create.json", { params: { slug: hotelUsedData.hotel.slug } })
          } else if (desktopUserLoginFormStatus.source === 'favorite') {
            axios
              .get("/api/main/favorites/create.json", { params: { slug: hotelUsedData.hotel.slug } })
          }
          dispatch(
            setDesktopUserLoginFormStatus({
              open: false,
              signin: false,
              register: false,
            })
          );
        }
      })
      .catch(function (error) {
        [
          {
            type: "manual",
            name: "email",
            message: I18n.t('devise.failure.invalid')
          },
          {
            type: "manual",
            name: "password",
            message: I18n.t('devise.failure.invalid')
          }
        ].forEach(({ name, type, message }) =>
          setError(name, { type, message })
        );
      });
  };

  const body = (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <div className="col-md-12">
            <Label>
              <strong>{I18n.t("form_email")}</strong>
            </Label>
            <input
              type="text"
              {...register("email")}
              name="email"
              className={errors.email ? "form-control error" : "form-control"}
              placeholder={I18n.t("form_email_ex")}
            />
            <p className="error">{errors.email?.message}</p>
          </div>

          <div className="col-md-12">
            <Label>
              <strong>{I18n.t("form_password")}</strong>
            </Label>
            <a style={{display: "inline-block", float: 'right', marginTop: '10px'}} title={I18n.t("forgot_password")} alt={I18n.t("forgot_password")} target="_blank" href={`/password/new?locale=${currentLocale}`} >{I18n.t("forgot_password")}</a>
            <input
              type="password"
              {...register("password")}
              name="password"
              className={errors.password ? "form-control error" : "form-control"}
              placeholder={I18n.t("form_password")}
            />
            <p className="error">{errors.password?.message}</p>
          </div>

          <div className="col-md-12 text-right">
            <button
              type="submit"
              id="registration_submit"
              className="btn btn-primary"
              style={{width: '100%'}}
            >
              {I18n.t("login")}
            </button>
          </div>
        </Row>
      </form>

    </>
  );

  return <React.Fragment>{body}</React.Fragment>;
}

export default UserLogin;
