import React from "react";
import { useEffect, useState } from "react";
import { BuilderComponent, builder, useIsPreviewing } from "@builder.io/react";
import NavDesktop from "../main/nav/NavDesktop.js";
import { useSelector, useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import {
  changeLocale,
  addCSRFToken
} from "../redux/actions/actions.js";
import Button from "@material-ui/core/Button";
import I18n from "../../i18n-js/index.js";
import { createMedia } from "@artsy/fresnel";
import NavMobile from "../main/nav/NavMobile.js";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import MarketingLandingProductSlide from "../main/components/slides/MarketingLandingProductSlide.js";
import axios from "axios";
import SelectArea from "../main/components/dialogs/SelectArea.js";
import {Helmet} from "react-helmet";

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    lg: 1024,
  },
});

// Put your API key here
builder.init("51c4483a55434a2c87bd705c818c4de4");

// set whether you're using the Visual Editor,
// whether there are changes,
// and render the content if found
export default function CatchAllRoute(props) {

  const dispatch = useDispatch();
  const isPreviewingInBuilder = useIsPreviewing();
  const [notFound, setNotFound] = useState(false);
  const [content, setContent] = useState(null);
  const [ready, setReady] = useState(false);
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState([])
  const csrfToken = useSelector((state) => state.csrfToken);
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;

  // get the page content from Builder
  useEffect(() => {
    async function fetchContent() {
      const content = await builder
        .get("marketing-landing-page", {
          url: window.location.pathname
        })
        .promise();

      getProducts(content)

      setContent(content);
      setReady(true);
      setNotFound(!content);
    }
    fetchContent();
  }, [window.location.pathname]);

  const getProducts = (content) => {
    axios.post('/api/v1/products', {product_ids: content.data.selectionIds.map(x => x.productid), path: content.data.url})
      .then((response) => {
        setProducts(response.data.data)
      })
  }

  const StyledDialog = withStyles({
    paper: {
      width: "120rem",
      height: "50rem",
    },
  })(Dialog);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  dispatch(changeLocale(props.locale));
  dispatch(addCSRFToken(props.csrf_token));
  const currentLocale = useSelector((state) => state.currentLocale)
  I18n.locale = currentLocale;

  const useStyles = makeStyles((theme) => ({
    root: {},
    hero: {
      position: "relative",
      width: "100%",
      padding: "0 0 2rem",
      backgroundColor: "#F7EB67",
    },
    heroLarge: {
      position: "relative",
      width: "100%",
      padding: "0 0 2rem",
      backgroundColor: "#F7EB67",
      marginBottom: '50px'
    },
    heroBlock: {
      padding: "0 25px 25px",
      textAlign: "left",
      fontFamily: 'TobiasTRIAL-Regular',
      background: '#F7EB67'
    },
    heroText: {
      marginTop: "0",
      fontSize: "24px",
      fontFamily: 'TobiasTRIAL-Regular',
      fontWeight: 'initial',
    },
    heroTextLarge: {
      marginTop: "0",
      fontSize: "4em",
      fontFamily: 'TobiasTRIAL-Regular',
      fontWeight: 'initial',
    },
    pTop200: {
      paddingTop: '140px',
    },
    heroTextBold: {
      fontFamily: 'TobiasTRIAL-Heavy'
    },
    linkText: {
      fontWeight: "800 !important",
      fontSize: "14px",
      marginBottom: "20px",
      borderRadius: 0,
      textTransform: 'none !important',
    },
    secondaryHeroText: {
      fontFamily: "TobiasTRIAL-Regular",
      fontSize: "18px",
      maxWidth: '90%',
    },
    verticalDivider: {
      display: "block",
      height: "2vh",
      width: "0",
      margin: "1rem auto 0",
      border: "1px solid black",
    },
    provinceBlock: {
      padding: "0 20px 10px",
    },
    provinceList: {
      display: "flex",
      flexWrap: "wrap",
      listStyle: "none",
      margin: "3rem -.5rem 0",
      padding: "0",
      justifyContent: "space-between"
    },
    provinceListMobile: {
      display: "flex",
      flexWrap: "no-wrap",
      flexDirection: "column",
      listStyle: "none",
      margin: "3rem -.5rem 0",
      padding: "0",
      justifyContent: "space-between",
    },
    province: {
      marginBottom: "10px",
      position: "relative",
      width: "100%",
      paddingRight: ".5rem",
      paddingLeft: ".5rem",
      flex: "0 0 50%",
      maxWidth: "25%",
    },
    category: {
      marginBottom: "50px",
      position: "relative",
      width: "100%",
      flex: "0 0 100%",
      paddingBottom: "4rem",
      maxWidth: "100%",
    },
    categoryLarge: {
      marginBottom: "10px",
      position: "relative",
      width: "100%",
      paddingBottom: "4rem",
      flex: "0 0 50%",
      maxWidth: "23.5%",
      cursor: "pointer",
    },
    categoryLargeCaption: {
      position: "absolute",
      bottom: "-30px",
      width: "100%",
      background: "#F4A583",
      color: "#7d2647",
      borderBottomRightRadius: "10px",
      borderBottomLeftRadius: "10px",
      height: "80px",
      padding: "20px 20px",
      fontFamily: "TobiasTRIAL-Regular",
      textAlign: "center",
      fontSize: "26px",
      cursor: 'pointer'
    },
    provinceName: {
      position: "absolute",
      zIndex: 1,
      display: "flex",
      top: 0,
      height: "100%",
      width: "80% !important",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      color: "#fff",
      fontWeight: 600,
      lineHeight: 1.2,
      fontSize: "2rem",
    },
    provinceNameLarge: {
      position: "absolute",
      zIndex: 1,
      display: "flex",
      top: 0,
      height: "100%",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      color: "#fff",
      fontWeight: 600,
      lineHeight: 1.2,
      fontSize: "3rem",
      paddingBlockEnd: "4rem",
      paddingRight: "4rem",
    },
    provinceImage: {
      width: "100%",
      height: "17vh",
      minHeight: "17rem",
      borderRadius: "10px",
      backgroundPosition: "50%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
    provinceImageLarge: {
      width: "100%",
      height: "250px",
      minHeight: "250px",
      borderRadius: "10px",
      backgroundPosition: "50%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
    experienceBlock: {
      padding: "0 20px",
      position: "relative",
      marginTop: "20px",
    },
    sectionTitle: {
      paddingLeft: '1.5rem',
    },
    sectionSubTitle: {
      paddingLeft: '1.5rem',
    },
    giftCardBlock: {
      padding: '0 4rem'
    },
    container: {
      width: '90%',
      margin: '0 auto',
      clear: "both",
    },
    yellowBlockLarge: {
      background: "#F7EB67",
      borderRadius: 20,
      margin: '50px auto 80px',
      padding: '25px 25px 70px 25px',
      position: 'relative',
      top: '50px',
      maxWidth: '85%'
    },
    yellowBlock: {
      background: "#F7EB67",
      borderRadius: 20,
      margin: '50px auto',
      padding: '25px',
      position: 'relative',
      maxWidth: '100%'
    },
    giftCardImage: {
      width: '100%'
    },
    blockRow: {
      paddingTop: "100px",
      paddingBottom: "100px",
      marginBottom: '-10px'
    },
    giftRowLarge: {
      background: "#F7EB67",
      backgroundImage: 'url(https://hotelbreak.s3.eu-west-3.amazonaws.com/v2/hotelbreak-gift-cards.jpg)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50vw 50%',
    },
    giftRow: {
      background: "#F7EB67",
    },
    socialRow: {
      background: "#F4A583",
      color: '#7d2647'
    },
    hoteliersRowLarge: {
      backgroundImage: 'url(https://hotelbreak.s3.eu-west-3.amazonaws.com/v2/hotelbreak-for-hoteliers.jpeg) !important',
    },
    logoBrandLarge: {
      maxWidth: "100px",
      marginTop: "-20px",
      display: 'inline-block',
      marginLeft: "50px",
      "&:nth-of-type(1), &:nth-of-type(4)": {
        marginLeft: '0 !important'
      }
    },
    logoBrand: {
      maxWidth: "80%",
      justifySelf: 'center',
    },
    noPadding: {
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
      marginLeft: '0 !important',
      marginRight: '0 !important',
    },
    hoteliersLogo: {
      display: 'grid',
      gridTemplateColumns: "1fr 1fr",
    },
    link: {
      textDecoration: 'underline'
    },
    uspSteps: {
      fontFamily: "TobiasTRIAL-ExtraBold",
      fontSize: "36px",
      paddingTop: "20px",
      width: "75%",
    },
    uspText: {
      fontFamily: "TobiasTRIAL-Regular",
      fontSize: "18px",
      maxWidth: "90%",
    },
    uspBlock: {
      color: "#7d2647",
      padding: "20px 40px",
      width: "32%",
      maxWidth: "32%",
      backgroundColor: "#F4A583",
      borderRadius: "10px",
      border: "1px solid #7d2647",
    },
    uspBlockMobile: {
      color: "#7d2647",
      padding: "20px 40px",
      width: "100%",
      maxWidth: "100%",
      marginBottom: "10px",
      backgroundColor: "#F4A583",
      borderRadius: "10px",
      border: "1px solid #7d2647",
    },
    uspBlockImage: {
      paddingTop: "20px",
      width: "75%",
    },
  }));

  const classes = useStyles();

  const landing = (col) => {
    return (
      <React.Fragment>
        <div className={clsx("row", classes.noPadding)}>
          <div className={classes.container}>
            <div className={classes.provinceBlock}>
              <h2 className={col < 3 ? classes.heroText : classes.heroTextLarge} style={{ paddingBottom: '30px'}}>
                <span className={classes.heroTextBold}>{content?.data?.selectionTitle}</span>
              </h2>
              {products?.length > 0 ?
                <MarketingLandingProductSlide
                  selector={"productSlide-mobile"}
                  listCols={col}
                  modal={true}
                  products={products}
                />
              : null }
            </div>
          </div>
        </div>
        <div className={clsx("row", classes.noPadding)}>
          <div className={classes.container}>
            <div className={classes.category} style={{ marginTop: "50px" }}>
              <ul
                className={
                  col < 3 ? classes.provinceListMobile : classes.provinceList
                }
              >
                {content?.data?.benefits.map((x, index) => {
                  return(
                    <li
                      className={col < 3 ? classes.uspBlockMobile : classes.uspBlock}
                    >
                      <div className="col-md-2 col-xs-2 no-padding">
                        <p className={classes.uspSteps}>{index + 1}</p>
                      </div>
                      <div
                        className={clsx(
                          "col-md-10 col-xs-10",
                          col < 3 && "no-padding"
                        )}
                      >
                        <p className={classes.uspText}>
                          {x.title}
                        </p>
                        <p>{x.content}</p>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className={clsx("row", classes.noPadding)}>
          <div className={classes.container}>
            <div dangerouslySetInnerHTML={{__html: content?.data?.seolongform}} />
          </div>
        </div>
      </React.Fragment>
    )
  }
  return (
    <>
      {content?.data ?
        <Helmet>
          <meta charSet="utf-8" />
          <title>{content.data.pageTitle}</title>
          <meta name="description" content={content.data.subh1} />
          <link rel="canonical" href={`http://www.hotelbreak.com/${content.data.url}`} />
          <meta property="og:type" content={"website"} />
          <meta property="og:title" content={content.data.h1} />
          <meta property="og:description" content={content.data.subh1} />
          <meta name="twitter:creator" content={"@hotelbreaker"} />
          <meta name="twitter:card" content={"website"} />
          <meta name="twitter:title" content={content.data.h1} />
          <meta name="twitter:description" content={content.data.subh1} />
        </Helmet>
      : null}
      <MediaContextProvider>
        <Media at="sm">
          <div className={clsx("row", classes.noPadding)} style={{ visibility: ready ? 'visible' : 'hidden' }}>
            <NavMobile source={"landing_page"} />
            <div>
              <div className="col-md-4 no-padding">
                <img src={content?.data?.heroImage} style={{width: '100%'}} />
              </div>
              <div className="col-md-8 no-padding">
                <div className={classes.heroBlock}>
                  <h1 className={clsx(classes.heroText)} style={{marginBottom: '30px', paddingTop: '50px'}}>
                    {" "}
                    <span className={classes.heroTextBold}>{content?.data?.h1}</span>
                  </h1>
                  <p className={classes.secondaryHeroText} style={{marginBottom: '30px'}}>
                  {content?.data?.subh1}
                  </p>
                  <Button
                    onClick={handleClickOpen}
                    className={classes.linkText + " btn btn-secondary"}
                  >
                    {" "}
                    {I18n.t("landing.view_experience_in_my_city")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {landing(1.3)}
        </Media>
        <Media at="lg">
        <div className={clsx("row", classes.noPadding)} style={{ visibility: ready ? 'visible' : 'hidden' }}>
            <div className={clsx(classes.heroLarge, "col-md-6")}>
            <NavDesktop source={"landing_page"} />
              <div className={classes.container}>
                <div className="col-md-8">
                  <div className={classes.heroBlock}>
                    <h1 className={clsx(classes.heroTextLarge, classes.pTop200)} style={{marginBottom: '30px', width: '130%', position: 'relative', zIndex: 1}}>
                      {" "}
                      <span className={classes.heroTextBold}>{content?.data?.h1}</span>
                    </h1>
                    <p className={classes.secondaryHeroText} style={{marginBottom: '30px', position: 'relative', zIndex: 1, maxWidth: '60%'}}>
                      {content?.data?.subh1}
                    </p>
                    <Button
                      onClick={handleClickOpen}
                      className={classes.linkText + " btn btn-secondary"}
                    >
                      {" "}
                      {I18n.t("landing.view_experience_in_my_city")}
                    </Button>
                    <p className={classes.link}>
                      <a href="/es/sobre-nosotros" target="_blank">
                        {I18n.t("about")}
                      </a>
                    </p>
                    <p className={classes.link}>
                      <a href="https://apps.apple.com/us/app/hotelbreak/id6478968856" target="_blank">
                      {I18n.t("dl_app")}
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <img src={content?.data?.heroImage} style={{width: '50vw', position: 'absolute', top: '180px', right: 0, zIndex: 0, maxHeight: "370px", objectFit: 'cover'}} />
                </div>
              </div>
            </div>
          </div>
          {landing(4.3)}
        </Media>
      </MediaContextProvider>
      <StyledDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <SelectArea handleClose={handleClose}></SelectArea>
      </StyledDialog>
    </>
  );
}