import React, { useEffect } from "react";
import CustomizeEvoucher from "./CustomizeEvoucher";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

function CustomizeVoucher(props) {
  const stepsVoucherCreationParams = useSelector(
    (state) => state.stepsVoucherCreationParams
  );

  useEffect(() => {
    if (!stepsVoucherCreationParams.step4.status) {
      axios
        .post("/api/main/tracking/checkouts/viewed.json", {
          params: { step: 4, step_name: "customize" },
        })
        .then((response) => {
          console.log(response.status);
        })
        .catch(function (error) {});
    }
  }, []);

  return (
    <CustomizeEvoucher
      handleNext={props.handleNext}
      handleBack={props.handleBack}
    />
  );
}

export default CustomizeVoucher;
