import React from "react";
import { useStylesPriceSelection } from "../../styles/stepStyles";
import clsx from "clsx";
import { Input, Label } from "reactstrap";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "@mui/material/Button";
import {
  setAreaHomeProducts,
  setStepsVoucherCreationParams,
  setAreaHomeHotelCategories,
} from "../../../../redux/actions/actions";
import axios from "axios";
import { convertDate, to_eur } from "../../../../../helpers/base";
import Emoji from "a11y-react-emoji";
import I18n from "../../../../../i18n-js/index.js.erb";
import HotelBreakLoader from "../../../components/loaders/hotelBreakLoader";
import { SET_CURRENT_USER } from "../../../../redux/actions/constants";

function ProductForm(props) {
  const schema = yup
    .object({
      voucherPrice: yup
        .number()
        .required(I18n.t("is_required"))
        .positive(I18n.t("validation_positive_number")),
    })
    .required();

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();
  const classes = useStylesPriceSelection();
  const [product, setProduct] = useState(false);
  const [voucherPrice, setVoucherPrice] = useState();

  const csrfToken = useSelector((state) => state.csrfToken);
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;

  const stepsVoucherCreationParams = useSelector(
    (state) => state.stepsVoucherCreationParams
  );

  const setPrice = (pvp, maxGuest) => {
    if (maxGuest === 1) {
      setVoucherPrice(to_eur(pvp) * 2);
      var price = to_eur(pvp) * 2;
      setValue("voucherPrice", price);
    } else {
      setVoucherPrice(to_eur(pvp));
      setValue("voucherPrice", to_eur(pvp));
    }
  };

  useEffect(() => {
    if (!stepsVoucherCreationParams.step1.status) {
      axios
        .get("/api/main/products/get_product.json", {
          params: { id: props.product, lang: I18n.locale },
        })
        .then((response) => {
          setProduct(response.data.data.attributes);
          setPrice(
            response.data.data.attributes.pvp,
            response.data.data.attributes.productTickets.max_guest
          );
          axios
            .post("/api/main/tracking/checkouts/started.json", {
              params: { amount: response.data.data.attributes.pvp },
            })
            .then((response) => {
              console.log(response.status);
            })
            .catch(function (error) {});
        })
        .catch(function (error) {});

      axios
        .post("/api/main/tracking/checkouts/viewed.json", {
          params: { step: 1, step_name: "price_selection" },
        })
        .then((response) => {
          console.log(response.status);
        })
        .catch(function (error) {});
    }
  }, []);

  const onSubmit = (data) => {
    dispatch(
      setStepsVoucherCreationParams({
        ...stepsVoucherCreationParams,
        step1: {
          status: true,
          price: data["voucherPrice"],
          product_id: props.product,
        },
        step2: {status: true, print: false, evoucher: true}
      })
    );

    axios
      .post("/api/main/tracking/checkouts/completed.json", {
        params: { step: 1, step_name: "price_selection" },
      })
      .then((response) => {
        console.log(response.status);
      })
      .catch(function (error) {});

    props.handleNext();
  };

  return (
    <div>

      <section
        className={clsx(
          props.mobile ? classes.productWrapMobile : classes.productWrap
        )}
      >
        {product ? (
          <>
            <div
              className={clsx(
                props.mobile ? classes.productBoxMobile : classes.productBox
              )}
            >
              <strong>{product.name}</strong>
              <p>
                <Emoji symbol="🏨" /> {product.hotel}
              </p>
              <p>
                <Emoji symbol="💶" />
                &nbsp;
                {product.productTickets.max_guest === 1
                  ? I18n.t("price_from_per_pers", {
                      price: to_eur(product.pvp),
                      currency: "€",
                    })
                  : I18n.t("price_from_per_x", {
                      price: to_eur(product.pvp),
                      currency: "€",
                      pers: product.productTickets.max_guest,
                    })}
              </p>
              {!props.mobile && (
                <div
                  className={clsx(props.mobile && classes.productDescMobile)}
                >
                  <small>Description</small>
                  <div
                    dangerouslySetInnerHTML={{ __html: product.description }}
                  />
                </div>
              )}
            </div>
            <div
              className={clsx(
                props.mobile ? classes.productBoxMobile : classes.productBox
              )}
            >
              <img
                className={classes.productImage}
                src={`https://www.hotelbreak.com/${product.blockPhoto}`}
              />
            </div>
            {props.mobile && (
              <div className={clsx(props.mobile && classes.productDescMobile)}>
                <small>Description</small>
                <div
                  dangerouslySetInnerHTML={{ __html: product.description }}
                />
              </div>
            )}
          </>
        ) : (
          <HotelBreakLoader />
        )}
      </section>
      {product && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Label className={classes.priceFieldLabel}>
            {product.productTickets.max_guest === 1
              ? `${I18n.t("price_from_per_pers", {
                  price: to_eur(product.pvp),
                  currency: "€",
                })} * 2 = ${to_eur(product.pvp) * 2}€`
              : I18n.t("price_from_per_x", {
                  price: to_eur(product.pvp),
                  currency: "€",
                  pers: product.productTickets.max_guest,
                })}
          </Label>
          <Controller
            name="voucherPrice"
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, onChange, ...field } }) => (
              <Input
                {...field}
                onChange={onChange}
                type="number"
                step={0.01}
                className={clsx(
                  classes.priceFieldMobile,
                  voucherPrice !== 0 && ` ${classes.selected}`,
                  errors.voucherPrice && "error"
                )}
                innerRef={ref}
              />
            )}
          />

          <p className="error">{errors.voucherPrice?.message}</p>

          <Button
            className={clsx(
              "btn btn-primary",
              props.mobile && classes.fullWidth
            )}
            style={{ marginTop: "20px" }}
            type="submit"
          >
            {I18n.t("new_gift__amount_button")}
          </Button>
          <div className={classes.alertBox}>
            <Emoji symbol="ℹ️" className={classes.discountBoxEmoji} />
            <div className={classes.discountBoxElemTitle}>
              <span className={classes.discountBoxTitle}>
                {I18n.t("new_gift__product_reco")}
              </span>
              <small>{I18n.t("new_gift__product_reco_explain")}</small>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default ProductForm;
