import React from "react";
import { useState, useEffect } from "react";
import ProductBlockFull from "../products/ProductBlockFull";
import { currencyToSymbol, to_eur } from "../../../helpers/base.js";
import I18n from "../../../i18n-js/index.js.erb";
import { makeStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import Emoji from "a11y-react-emoji";
import slugify from "react-slugify";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import Fab from "@material-ui/core/Fab";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import moment from "moment";
import clsx from "clsx";

function SlideProductBlock(props) {
  const [hasLeftScroll, setHasLeftScroll] = useState(false);
  const [hasRightScroll, setHasRightScroll] = useState(true);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      overflow: "hidden",
      margin: "20px 0 0",
      padding: "0 10px",
    },
    gridList: {
      flexWrap: "nowrap",
      bottom: "-29px",
      right: "-20px",
      position: "static",
      overflowY: "auto",
    },
    title: {
      paddingLeft: "10px",
      width: "100%",
      marginLeft: props.device == "desktop" ? "5% !important" : "",
    },
    link: {
      fontWeight: "800",
      color: "black",
      textDecoration: "none",
      padding: "0 10px",
      marginTop: "10px",
      fontFamily: "Futura, Didact Gothic, sans-serif",
    },
    gridWrapper: {
      width: "100%",
      height: "350px",
      overflow: "hidden",
      position: "relative",
      marginRight: "0px",
    },
    buttonLeft: {
      position: "absolute",
      marginTop: "9rem",
      marginLeft: "20px",
      zIndex: "10",
      background: "#F7EB67",
    },
    buttonRight: {
      position: "absolute",
      zIndex: "10",
      right: "10px",
      background: "#F7EB67",
      marginTop: "9rem",
      marginRight: "10px",
    },
    viewAll: {
      height: "20rem",
      width: "100%",
      borderRadius: "10px",
      objectFit: "cover",
      border: "1px solid darkgrey",
      background: "white",
    },
    viewAllText: {
      bottom: "-16rem",
      left: "20px",
      position: "relative",
      "&:hover": {
        textDecoration: "none !important",
      },
    },
    link: {
      color: "black",
      "&:hover": {
        textDecoration: "none !important",
      },
    },
    over: {
      zIndex: 11,
    },
    categoryViewAll: {
      float: "right",
      display: "inline-block",
      fontSize: "1.4rem",
      marginTop: props.device == 'desktop' ? "10px" : '-10px',
      marginBottom: props.device == 'desktop' ? "inherit" : '20px',
      marginRight: "5vw",
      border: '1px solid black',
      padding: '10px',
      "&:hover": {
        color: 'black',
        boxShadow: "4px 4px 0px #f7eb67",
      }
    },
    firstListElem: {
      marginLeft: "5% !important",
    },
    yellow: {
      margin: "40px 0",
      background: '#f7eb67',
      padding: '20px 0'
    },
    hasShowAll: {
      marginTop: '-25px'
    }
  }));

  const classes = useStyles();

  const scrollRight = () => {
    $(`#gridListScroll-${props.selector}`).animate(
      {
        scrollLeft: "+=400px",
      },
      "slow"
    );
    setHasLeftScroll(true);
    if (
      $(`#gridListScroll-${props.selector}`).get(0).scrollWidth -
        $(`#gridListScroll-${props.selector}`).scrollLeft() -
        $(`#gridListScroll-${props.selector}`).width() -
        400 <=
      0
    ) {
      setHasRightScroll(false);
    }
  };

  const scrollLeft = () => {
    $(`#gridListScroll-${props.selector}`).animate(
      {
        scrollLeft: "-=400px",
      },
      "slow"
    );
    setHasRightScroll(true);
    if ($(`#gridListScroll-${props.selector}`).scrollLeft() - 400 <= 0) {
      setHasLeftScroll(false);
    }
  };

  const selectAdults = (adults) => {
    if (adults && adults !== false && adults !== 0) {
      return "&adults=" + adults;
    } else {
      return "";
    }
  };

  const selectKids = (kids) => {
    if (kids && kids !== false && kids !== 0) {
      return "&kids=" + kids;
    } else {
      return "";
    }
  };

  const slug = (slug, date, adults, kids) => {
    switch (slug) {
      case "most-popular":
        return `experiences?orderby=mostPopular${
          date && "&available_date=" + date
        }${adults}${kids}`;
      case "most-recent":
        return `experiences?orderby=mostRecent${
          date && "&available_date=" + date
        }${adults}${kids}`;
      case "family-friendly":
        return `family-friendly?${date && "&available_date=" + date}${adults}${kids}`;
      case "all-inclusive":
        return `all-inclusive?${
          date && "&available_date=" + date
        }${adults}${kids}`;
      case "adults-only":
        return `adults-only?${
          date && "&available_date=" + date
        }${adults}${kids}`;
      case "recommended":
        return;
      case "recommended_south":
        return;
      case "recommended_north":
        return;
      default:
        return `${slugify(I18n.t(slug))}?${
          date && "&available_date=" + date
        }${adults}${kids}`;
    }
  };

  return (
    <div>
      <div className={clsx(classes.root, props.yellow && classes.yellow)}>
        <h3 className={classes.title}>
          {props.title}
          {props.products &&
            props.products.length > props.listCols &&
            props.show_all_link && (
              <a
                href={`/${props.locale}/${props.area}/${slug(
                  props.selector,
                  props.selectedDate,
                  selectAdults(props.selectedPax.adults),
                  selectKids(props.selectedPax.kids)
                )}`}
                target="_blank"
                className={classes.link}
              >
                <span className={classes.categoryViewAll}>
                  {I18n.t("view_all")} <Emoji symbol="→" />
                </span>
              </a>
            )}
        </h3>
        {props.selector !== 'recommended_south' && props.selector !== 'recommended_north' &&
          <p className={clsx(classes.title, props.products &&
            props.products.length > props.listCols &&
            props.show_all_link && classes.hasShowAll)}>
              {props.category_description}
          </p>
        }
        <div className={classes.gridWrapper}>
          {hasLeftScroll && (
            <Fab
              color="white"
              aria-label="add"
              className={classes.buttonLeft}
              onClick={scrollLeft}
            >
              <ArrowLeftIcon />
            </Fab>
          )}
          {hasRightScroll &&
            props.products !== undefined &&
            props.products.data.length > props.listCols && (
              <Fab
                color="white"
                aria-label="add"
                className={classes.buttonRight}
                onClick={scrollRight}
              >
                <ArrowRightIcon />
              </Fab>
            )}
          <ImageList
            className={classes.gridList}
            cols={props.listCols}
            rowHeight="auto"
            id={`gridListScroll-${props.selector}`}
          >
            {props.products !== undefined &&
              props.products.data.map(function (product, index) {
                return (
                  <ImageListItem
                    key={product.id}
                    className={clsx(
                      index == 0 &&
                        props.device == "desktop" &&
                        classes.firstListElem
                    )}
                  >
                    <ProductBlockFull
                      key={product.id}
                      id={product.id}
                      name={product.attributes.name}
                      block_photo={product.attributes.block_photo}
                      week_price={to_eur(product.attributes.pvp)}
                      base_week_price={to_eur(product.attributes.base_pvp)}
                      listing_name={product.attributes.listing_name}
                      is_direct_booking={product.attributes.direct_booking}
                      star_rating={product.attributes.star_rating}
                      city={product.attributes.room_city}
                      area={props.area}
                      max_guest={product.attributes.max_guest}
                      price_model={product.attributes.price_model}
                      currency={currencyToSymbol(product.attributes.currency)}
                      slug={product.attributes.slug}
                      roomSlug={product.attributes.room_slug}
                      hotel={props.hotel}
                      product_slug={props.product_slug}
                      disabledGiftVoucher={product.attributes.room_disable_gift_voucher}
                      is_new={
                        moment(product.attributes.bookable_at || product.attributes.created_at) >
                        moment().subtract(2, "months")
                      }
                      timeDistance={product.attributes.time_distance}
                      selectedDate={props.selectedDate}
                      selectedPax={props.selectedPax}
                      averageRating={props}
                      has_calendar={product.attributes.has_calendar}
                    />
                  </ImageListItem>
                );
              })}
            {props.products !== undefined &&
              props.products.length > props.listCols &&
              props.show_all_link && (
                <ImageListItem
                  key={props.products.length + 1}
                  className={classes.over}
                >
                  <a
                    href={`/${props.locale}/${props.area}/${slug(
                      props.selector,
                      props.selectedDate,
                      selectAdults(props.selectedPax.adults),
                      selectKids(props.selectedPax.kids)
                    )}`}
                    target="_blank"
                    className={classes.link}
                  >
                    <div className={classes.viewAll}>
                      <p className={classes.viewAllText}>
                        {I18n.t("view_all")} <Emoji symbol="→" />
                      </p>
                    </div>
                  </a>
                </ImageListItem>
              )}
          </ImageList>
        </div>
        {props.link_text !== undefined && (
          <a
            href={props.link_destination}
            className="toRoom marginbottom"
            alt={props.link_text}
          >
            <div className={"btn btn-primary"}>
              {props.link_text} <ArrowRightAltIcon />
            </div>
          </a>
        )}
      </div>
    </div>
  );
}

export default SlideProductBlock;
