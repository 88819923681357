import React, { useState } from "react";
import I18n from "../../i18n-js/index.js.erb";
import { useStylesPayment } from "./styles/paymentOptionsStyles";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import { setCurrentUser, setPaymentData } from "../redux/actions/actions";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const StyledCircularProgress = withStyles({
  root: {
    marginTop: "3rem",
    float: "right",
  },
})(CircularProgress);

function UserBillingStep(props) {
  const dispatch = useDispatch();
  const classes = useStylesPayment(props);
  const FORM_SOURCE = "Billing data";
  const reservation_code = new URLSearchParams(window.location.search).get(
    "reservation_code"
  );

  const analyticsTrack = (eventType, inputHash) => {
    analytics.track(eventType, { inputHash, form: FORM_SOURCE });
  };

  const currentUser = useSelector((state) => state.currentUser);
  const FunnelData = useSelector((state) => state.PaymentData);
  const currentLocale = useSelector((state) => state.currentLocale);
  const [loader, setLoader] = useState(false);

  I18n.locale = currentLocale;

  const schema = yup
    .object({
      billing_address: yup.string().required(I18n.t("is_required")),
      billing_city: yup.string().required(I18n.t("is_required")),
      billing_postal_code: yup.string().required(I18n.t("is_required")),
      billing_province: yup.string().required(I18n.t("is_required")),
      billing_country: yup.string().required(I18n.t("is_required")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    setLoader(true);
    axios
      .put(`/api/main/funnel/users/${currentUser.id}`, {
        billing_address: data.billing_address,
        billing_city: data.billing_city,
        billing_postal_code: data.billing_postal_code,
        billing_province: data.billing_province,
        billing_country: data.billing_country,
        reservation_code: reservation_code,
        billing_info_step: true,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            setPaymentData({
              ...FunnelData,
              billable: true,
            })
          );
          setLoader(false);
        }
      })
      .catch(function (error) {});
  };

  return (
    <React.Fragment>
      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="col-md-7">
          <h4 className={classes.headings}>
            <span className="number_step">2</span>&nbsp;&nbsp;
            {I18n.t("checkout_billing_step")}
          </h4>
          <div className="row">
            <div className="col-md-12">
              <p>{I18n.t("billing_explain")}</p>
              <label
                htmlFor="billing_address"
                className={errors.billing_address && classes.error}
              >
                {I18n.t("billing_address")}
              </label>
              <input
                type="text"
                {...register("billing_address")}
                className="form-control"
                name="billing_address"
                placeholder={I18n.t("billing_address")}
                value={currentUser.billingAddress}
              />
              <p className={errors.billing_address && classes.error}>
                {errors.billing_address?.message}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label
                htmlFor="billing_city"
                className={errors.billing_city && classes.error}
              >
                {I18n.t("billing_city")}
              </label>
              <input
                type="text"
                {...register("billing_city")}
                className="form-control"
                name="billing_city"
                placeholder={I18n.t("billing_city")}
                value={currentUser.billingCity}
              />
              <p className={errors.billing_city && classes.error}>
                {errors.billing_city?.message}
              </p>
            </div>
            <div className="col-md-6">
              <label
                htmlFor="billing_postal_code"
                className={errors.billing_postal_code && classes.error}
              >
                {I18n.t("billing_postal_code")}
              </label>
              <input
                type="text"
                {...register("billing_postal_code")}
                className="form-control"
                name="billing_postal_code"
                placeholder={I18n.t("billing_postal_code")}
                value={currentUser.billingPostalCode}
                minLength={5}
              />
              <p className={errors.billing_postal_code && classes.error}>
                {errors.billing_postal_code?.message}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label
                htmlFor="billing_province"
                className={errors.billing_province && classes.error}
              >
                {I18n.t("billing_address")}
              </label>
              <input
                type="text"
                {...register("billing_province")}
                className="form-control"
                name="billing_province"
                placeholder={I18n.t("billing_province")}
                value={currentUser.billingProvince}
              />
              <p className={errors.billing_province && classes.error}>
                {errors.billing_province?.message}
              </p>
            </div>
            <div className="col-md-6">
              <label
                htmlFor="billing_country"
                className={errors.billing_country && classes.error}
              >
                {I18n.t("billing_country")}
              </label>
              <input
                type="text"
                {...register("billing_country")}
                className="form-control"
                name="billing_country"
                placeholder={I18n.t("billing_country")}
                value={currentUser.billingCountry}
              />
              <p className={errors.billing_country && classes.error}>
                {errors.billing_country?.message}
              </p>
            </div>
          </div>
          {loader && <StyledCircularProgress />}
          {!loader && (
            <button
              className={clsx(
                "btn",
                props.brand?.data != null ? classes.primaryColor : "btn-primary"
              )}
              type="submit"
              onClick={() => analyticsTrack("data")}
              style={{ float: "right" }}
            >
              {I18n.t("payment_form__continue_button")}{" "}
            </button>
          )}
          <br />
          <br />
        </div>
      </form>
    </React.Fragment>
  );
}

export default UserBillingStep;
