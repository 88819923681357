import React from "react";
import NavMobile from "./main/nav/NavMobile";
import NavDesktop from "./main/nav/NavDesktop";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import I18n from "../i18n-js/index.js.erb";
import axios from "axios";
import UserLoginWrap from "./main/components/modals/UserLogin/UserLoginWrap";
import FilterBar from "./main/filters/FilterBar";
import {
  addCSRFToken,
  setCurrentUser,
  changeLocale,
  changeSelectedArea,
  setAreaHomeProducts,
  changeSelectedAreaName,
  setAreaHomeHotelCategories,
} from "./redux/actions/actions";
import { createMedia } from "@artsy/fresnel";
import slugify from "react-slugify";

let noMarginTop = {
  marginTop: "0",
};

let resultTitleStyles = {
  fontSize: "20px",
  padding: "0 0 0 15px",
};

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    lg: 1024,
  },
});

function EmptyProductList(props) {
  const dispatch = useDispatch();
  axios.defaults.headers.common["X-CSRF-Token"] = props.csrf_token;
  const [ready, setReady] = useState(false);
  const adults = [null, undefined, 0].includes(props.adults) ? 1 : props.adults;
  const kids = [null, undefined, 0].includes(props.kids) ? 0 : props.kids;
  dispatch(addCSRFToken(props.csrf_token));
  dispatch(changeLocale(props.current_locale));

  const selectedArea = useSelector((state) => state.area);
  const currentLocale = useSelector((state) => state.currentLocale);
  const selectedAreaName = useSelector((state) => state.areaName);

  I18n.locale = currentLocale;

  useEffect(() => {
    setReady(true);
  }, []);

  if (props.current_user && props.wallet && !ready) {
    dispatch(
      setCurrentUser({
        ...props.current_user,
        wallet: props.wallet,
      }),
      changeLocale(props.locale)
    );
  } else {
    changeLocale(props.locale);
  }

  useEffect(() => {
    if ((props.area || props.province) && selectedArea) {
      dispatch(changeSelectedArea(selectedArea));
      var location_params = selectedArea;
    } else if ((props.area || props.province) && !selectedArea) {
      dispatch(changeSelectedArea(props.area));
      var location_params = props.area;
    }
    axios
      .get("/api/main/products", {
        params: { location: location_params, locale: currentLocale },
      })
      .then((response) => {
        dispatch(
          setAreaHomeProducts(
            response.data.area_home_products
              .map((product_list) => {
                return {
                  category: product_list.products.map(
                    (productSerialization) => productSerialization.attributes
                  ),
                  title: product_list.title,
                  slug: product_list.slug,
                  emoji: product_list.emoji,
                  popularity: product_list.popularity,
                  type: product_list.type,
                };
              })
              .sort((a, b) => {
                if (a.popularity > b.popularity) {
                  return -1;
                } else if (
                  a.popularity === "most_popular" ||
                    a.popularity === "most_recent"
                ) {
                  return -1;
                } else {
                  return 1;
                }
              })
          )
        );
        dispatch(setAreaHomeHotelCategories(response.data.rooms_categories));
        dispatch(changeSelectedAreaName(response.data.province));
      });
  }, [selectedArea]);

  return (
    <div style={{ visibility: ready ? "visible" : "hidden" }}>
      <MediaContextProvider>
        <Media at="sm">
          <UserLoginWrap fullscreen={true} />
        </Media>

        <Media at="md">
          <UserLoginWrap fullscreen={false} />
        </Media>

        <Media greaterThanOrEqual="lg">
          <UserLoginWrap fullscreen={false} />
        </Media>
      </MediaContextProvider>
      <MediaContextProvider>
        <Media at="sm">
          <NavMobile index={true} areaName={selectedAreaName} />
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <FilterBar
              text_list={props.text_list}
              current_locale={currentLocale}
              hotel_search__filter={props.hotel_search__filter}
              count_filters={props.count_filters}
              initial_filter={props.order_by}
              filterrific_params={props.filterrific_params}
              exp_var={props.exp_var}
              source={"experiences"}
              product_category={props.product_category}
              adults={adults}
              kids={kids}
              wallet_friendly={props.wallet_friendly}
              price_high={props.price_high}
              price_low={props.price_low}
              available_date={props.available_date}
              categories={props.categories}
            />

            <div className="container">
              <h1
                className="result_title nodisplay_above768"
                style={resultTitleStyles}
                dangerouslySetInnerHTML={{ __html: props.text_list }}
              ></h1>

              <div className="row" style={noMarginTop}>
                <div className="col-md-12"></div>
              </div>
              <div className="panel-body">
                <div className="container">
                  <div className="row text-center">
                    <div className="col-md-8 col-md-offset-2">
                      <img
                        className="img-circle"
                        src="https://www.hotelbreak.com/assets/empty-2f874e11f215f5325b41a6dfb64e9fad50662ad6f36ed29270b3bee8037d1bd5.png"
                        style={{
                          width: "50%",
                          margin: "20px auto 0 auto",
                          display: "block",
                        }}
                      />
                      <h1 className="result_title">
                        {I18n.t("empty_search_results_message")}
                        <span
                          className="font-italic"
                          style={{ fontStyle: "italic", fontWeight: 700 }}
                        >
                          {" "}
                          {I18n.t("in_preposition")} {selectedAreaName}
                        </span>
                      </h1>
                      <br />
                      <a href={`/${I18n.locale}/${slugify(selectedArea)}/experiences`} target="_blank" >
                        <div className="btn btn-primary">
                          {I18n.t("view_all_experiences")}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Media>
        <Media greaterThanOrEqual="lg">
          <NavDesktop index={true} areaName={selectedAreaName} />
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <FilterBar
              text_list={props.text_list}
              current_locale={currentLocale}
              hotel_search__filter={props.hotel_search__filter}
              count_filters={props.count_filters}
              initial_filter={props.order_by}
              exp_var={props.exp_var}
              area={props.area}
              filterrific_params={props.filterrific_params}
              source={"experiences"}
              product_category={props.product_category}
              adults={adults}
              kids={kids}
              wallet_friendly={props.wallet_friendly}
              price_high={props.price_high}
              price_low={props.price_low}
              available_date={props.available_date}
              categories={props.categories}
            />

            <div className="container">
              <h1
                className="result_title nodisplay_above768"
                style={resultTitleStyles}
                dangerouslySetInnerHTML={{ __html: props.text_list }}
              ></h1>

              <div className="row" style={noMarginTop}>
                <div className="col-md-12"></div>
              </div>

              <div className="panel-body">
                <div className="container">
                  <div className="row text-center">
                    <div className="col-md-8 col-md-offset-2">
                      <img
                        className="img-circle"
                        src="https://www.hotelbreak.com/assets/empty-2f874e11f215f5325b41a6dfb64e9fad50662ad6f36ed29270b3bee8037d1bd5.png"
                        style={{
                          width: "50%",
                          margin: "20px auto 0 auto",
                          display: "block",
                        }}
                      />
                      <h1 className="result_title">
                        {I18n.t("empty_search_results_message")}
                      </h1>
                      <br />
                      <a href={`/${currentLocale}/${slugify(selectedArea)}/experiences`} target="_blank" >
                        <div className="btn btn-primary">
                          {I18n.t("view_all_experiences")}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Media>
      </MediaContextProvider>
    </div>
  );
}

export default EmptyProductList;
