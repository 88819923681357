import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Emoji from "a11y-react-emoji";
import Button from "@mui/material/Button";
import axios from "axios";
import { changeLocale } from "../../../redux/actions/actions";

let selectorStyle = {
  width: "fit-content",
  float: "right",
  marginTop: "7px",
  marginRight: "1em",
  background: 'transparent',
  color: 'black',
  fontFamily: 'TobiasTRIAL-SemiBold',
};

function LanguageSelector(props) {
  const dispatch = useDispatch();
  const currentLocale = useSelector((state) => state.currentLocale);
  const csrfToken = useSelector((state) => state.csrfToken);
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;

  const handleChange = (event) => {
    const get_selected_lg = event.target.value;
    axios
      .post("/api/main/locale/set_locale_interface.json", {
        locale: get_selected_lg,
        url: location.href,
        path: location.pathname,
        source: props.source,
      })
      .then((response) => {
        if (response.status && response.status === 200) {
          dispatch(changeLocale(get_selected_lg));
          location.href = location.origin + "/" + response.data.translated_url;
        } else {
          throw new Error("Error");
        }
      })
      .catch((error) => {
        console.log(error);
        location.href = location.origin + "/" + get_selected_lg;
      });
  };

  const languageOptions = {
    es: "🇪🇸 Español",
    fr: "🇫🇷 Français",
    en: "🇬🇧 English",
    it: "🇮🇹 Italiano",
    pt: "🇵🇹 Português",
    de: "🇩🇪 Deutsch"
  };

  return (
    <>
      <select
        style={selectorStyle}
        onChange={(ev) => handleChange(ev)}
        name="language_selector"
        id="language_selector"
      >
        <option value={currentLocale}>{languageOptions[currentLocale]}</option>
        {"es" !== currentLocale && <option value="es">🇪🇸 Español</option>}
        {"fr" !== currentLocale && <option value="fr">🇫🇷 Français</option>}
        {"en" !== currentLocale && <option value="en">🇬🇧 English</option>}
        {"it" !== currentLocale && <option value="it">🇮🇹 Italiano</option>}
        {"pt" !== currentLocale && <option value="pt">🇵🇹 Português</option>}
        {"de" !== currentLocale && <option value="de">🇩🇪 Deutsch</option>}
      </select>
    </>
  );
}

export default LanguageSelector;
