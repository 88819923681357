import "react-phone-input-2/lib/style.css";
import I18n from "../../../../i18n-js/index.js.erb";
import React, { useState } from "react";
import { Label, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import { setCurrentUser, setPaymentData } from "../../../redux/actions/actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import LoginButtons from "../../../main/components/buttons/loginButtons";
import PasswordRecovery from "../modals/PasswordRecovery";
import clsx from "clsx";
import { useStylesPayment } from "../../styles/paymentOptionsStyles";

const StyledCircularProgress = withStyles({
  root: {
    marginTop: "3rem",
  },
})(CircularProgress);

const reservation_code = new URLSearchParams(window.location.search).get(
  "reservation_code"
);

function ReservationUserLog(props) {
  const dispatch = useDispatch();
  const classes = useStylesPayment(props);
  const currentLocale = useSelector((state) => state.currentLocale);
  const csrfToken = useSelector((state) => state.csrfToken);
  const FunnelData = useSelector((state) => state.PaymentData);
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;

  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const FORM_SOURCE = "Reservation funnel user logging";

  const analyticsTrack = (eventType, inputHash) => {
    analytics.track(eventType, { inputHash, form: FORM_SOURCE });
  };

  const handlePasswordRecoveryOpen = () => {
    dispatch(
      setPaymentData({ ...FunnelData, passwordRecovery: { open: true } })
    );
  };

  const handlePasswordRecoveryClose = () => {
    dispatch(
      setPaymentData({ ...FunnelData, passwordRecovery: { open: false } })
    );
  };

  const schema = yup.object({
    email: yup
      .string()
      .email(I18n.t("reservation_email_format"))
      .required(I18n.t("is_required")),
    password: yup.string().required(I18n.t("is_required")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  I18n.locale = currentLocale;

  const onSubmit = (data) => {
    setLoader(true);
    axios
      .post("/api/main/registrations/create", {
        ...data,
        reservation_code: reservation_code,
      })
      .then((response) => {
        setLoader(false);
        setErrorMessage(false);
        dispatch(
          setCurrentUser(response.data.current_user_hash.data.attributes)
        );
      })
      .catch(function (error) {
        setLoader(false);
        setErrorMessage(error.response.data.error);
      });
  };

  return (
    <div>
      <React.Fragment>
        <PasswordRecovery handleClose={handlePasswordRecoveryClose} />
        <form onSubmit={handleSubmit(onSubmit)}>
          {FunnelData && !FunnelData.used_email ? (
            <LoginButtons source={FORM_SOURCE} />
          ) : (
            <p>{I18n.t("already_registered")}</p>
          )}
          <Row>
            <div className="col-md-12">
              <Label>
                <strong>{I18n.t("form_email")}</strong>
              </Label>
              <input
                type="text"
                {...register("email")}
                name="email"
                defaultValue={
                  FunnelData.used_email ? FunnelData.used_email : ""
                }
                className={errors.email ? "form-control error" : "form-control"}
                placeholder={I18n.t("form_email_ex")}
                onBlur={(ev) =>
                  analyticsTrack("Filled email", {
                    input: ev.target.value,
                  })
                }
                onFocus={(ev) =>
                  analyticsTrack("Entered email", {
                    input: ev.target.value,
                  })
                }
              />
              <p className="error">{errors.email?.message}</p>
            </div>

            <div className="col-md-12">
              <Label>
                <strong>{I18n.t("form_password")}</strong>
              </Label>
              <a
                style={{
                  display: "inline-block",
                  float: "right",
                  marginTop: "10px",
                  cursor: "pointer",
                }}
                title={I18n.t("forgot_password")}
                alt={I18n.t("forgot_password")}
                onClick={() => handlePasswordRecoveryOpen()}
                onBlur={(ev) => analyticsTrack("Filled forgot_password", {})}
                onFocus={(ev) => analyticsTrack("Entered forgot_password", {})}
              >
                {I18n.t("forgot_password")}
              </a>
              <input
                type="password"
                {...register("password")}
                name="password"
                className={
                  errors.password ? "form-control error" : "form-control"
                }
                placeholder={I18n.t("form_password")}
                onBlur={() =>
                  analyticsTrack("Filled second field password", {})
                }
                onFocus={() =>
                  analyticsTrack("Entered second field password", {})
                }
              />
              <p className="error">{errors.password?.message}</p>
            </div>
          </Row>

          <div
            className="col-md-6 text-left no-padding"
            style={{ textDecoration: "underline dotted", cursor: "pointer" }}
            onClick={() =>
              dispatch(setPaymentData({ ...FunnelData, log_in_form: false }))
            }
          >
            {I18n.t("dont_have_account")}
          </div>
          <div className="col-md-6 text-right no-padding">
            {loader && <StyledCircularProgress />}
            {!loader && (
              <button
                className={clsx("btn", props.brand?.data != null ? classes.primaryColor : "btn-primary")}
                type="submit"
                onClick={() => analyticsTrack("Continue to payment")}
              >
                {I18n.t("payment_form__continue_button")}{" "}
              </button>
            )}
            {!loader && errorMessage && <p className="error">{errorMessage}</p>}
          </div>
        </form>
      </React.Fragment>
    </div>
  );
}

export default ReservationUserLog;
