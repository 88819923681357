import React from 'react'
import { useState, useEffect } from "react";
import RoomBlock from "../../roomblock";
import { currencyToSymbol, to_eur, numDaysBetween } from "../../../helpers/base.js";
import I18n from "../../../i18n-js/index.js.erb";
import { makeStyles } from "@material-ui/core/styles";
import ImageList from '@material-ui/core/ImageList'
import ImageListItem from '@material-ui/core/ImageListItem'
import slugify from "react-slugify";
import { useSelector, useDispatch } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import Fab from '@material-ui/core/Fab';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import moment from "moment";


function HotelSlide(props) {

  const [hasLeftScroll, setHasLeftScroll] = useState(false);
  const [hasRightScroll, setHasRightScroll] = useState(true);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      overflow: "hidden",
      margin: "20px 0 0",
      padding: "0 10px",
    },
    gridList: {
      flexWrap: "nowrap",
    },
    title: {
      paddingLeft: "10px",
    },
    buttonLeft: {
      position: 'absolute',
      marginTop: '12%',
      marginLeft: '20px',
      zIndex: '10',
      background: 'white'
    },
    buttonRight: {
      position: "absolute",
      zIndex: "10",
      right: "10px",
      background: "white",
      marginTop: "12%",
      marginRight: "10px"
    }
  }));

  const classes = useStyles();

  const scrollRight = () => {
    $(`#gridListScroll-${props.selector}`).animate({
      scrollLeft: "+=400px"
    }, "slow");
    setHasLeftScroll(true)
    if ($(`#gridListScroll-${props.selector}`).get(0).scrollWidth-$(`#gridListScroll-${props.selector}`).scrollLeft()-$(`#gridListScroll-${props.selector}`).width()-400 <= 0) {
      setHasRightScroll(false)
    }
  }

  const scrollLeft = () => {
    $(`#gridListScroll-${props.selector}`).animate({
      scrollLeft: "-=400px"
    }, "slow");
    setHasRightScroll(true)
    if ($(`#gridListScroll-${props.selector}`).scrollLeft()-400 <= 0) {
      setHasLeftScroll(false)
    }
  }

  

  return (
    <React.Fragment>

      {props.hotels ? (
        <>
        {hasLeftScroll && 
          <Fab color="white" aria-label="add" className={classes.buttonLeft} onClick={scrollLeft}>
            <ArrowLeftIcon />
          </Fab>
        }
        { hasRightScroll && props.hotels.length > props.listCols &&
          <Fab color="white" aria-label="add" className={classes.buttonRight} onClick={scrollRight}>
            <ArrowRightIcon />
          </Fab>
        }
        <ImageList
          className={classes.gridList}
          cols={props.listCols}
          rowHeight="auto"
          id={`gridListScroll-${props.selector}`}
        >
          {props.hotels.map((room) => {
            return (
              <ImageListItem key={room.id}>
                <RoomBlock
                  key = {room.id}
                  roomid = {room.id}
                  area={room.area.toUpperCase()}
                  city = {room.city.name}
                  category = {room.category.toLowerCase()}
                  listing_name = {room.listing_name}
                  direct_booking = {room.direct_booking}
                  block_photo = {`https://www.hotelbreak.com${room.block_photo}`}
                  is_closed_today = {room.is_closed_today}
                  is_new = {  moment(room.bookable_at || room.created_at) >
                              moment().subtract(2, "months") }
                  review_note = {room.average_review}
                  star_rating = {room.star_rating}
                  slug = {room.slug}
                  favoriteids = {props.favoriteids}
                  price_model = {room.price_model}
                  price = {to_eur(room.lowest_price)}
                  min_price_max_guest = {room.min_price_max_guest}
                  margin = {room.margin}
                  currency = {currencyToSymbol(room.currency)}
                  current_user = {props.current_user}
                  reviews_count = {room.reviews_count}
                  product_categories = {room.product_categories}
                  source = {'hotelSlide'}
                  current_locale = {props.current_locale}
                  timeDistance = {room.time_distance}
                  brand={props.brand}
                />
              </ImageListItem>
            );
          })}
        </ImageList>
        
        </>
      ) :  <Skeleton variant="rect" width="100%" height="20rem" /> }
    </React.Fragment>
  );
}

export default HotelSlide
