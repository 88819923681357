import React from "react";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import {
  configAreaReducer,
  changeLocaleReducer,
  configCsrfTokenReducer,
  changeHotelDataReducer,
  currentUserReducer,
  currentAvailabilities,
  desktopUserLoginFormStatus,
  areaHomeProductsReducer,
  stepsVoucherCreationParams,
  clientSecret,
  configAreaNameReducer,
  selectedTimeFrameReducer,
  areaHomeHotelCategoriesReducer
} from "./redux/reducers/reducers";
import Hotel from "./main/hotels/Hotel";
import WidgetHotel from "./widget/WidgetHotel";
import WidgetHotelGhost from "./widget/Layouts/Ghost/WidgetHotelGhost";
 

const store = configureStore({
  reducer: {
    area: configAreaReducer,
    areaName: configAreaNameReducer,
    currentLocale: changeLocaleReducer,
    csrfToken: configCsrfTokenReducer,
    hotelUsedData: changeHotelDataReducer,
    currentUser: currentUserReducer,
    currentAvailabilities: currentAvailabilities,
    desktopUserLoginFormStatus: desktopUserLoginFormStatus,
    areaHomeProducts: areaHomeProductsReducer,
    stepsVoucherCreationParams: stepsVoucherCreationParams,
    clientSecret: clientSecret,
    selectedTimeFrame: selectedTimeFrameReducer,
    areaHomeHotelCategories: areaHomeHotelCategoriesReducer,
  },
});

function HotelApp(props) {
  return (
    <Provider store={store}>
      {props.brand ?
        props.serialized_brand?.data?.attributes?.template == 'ghost' ?
          <WidgetHotelGhost {...props} />
        :
          <WidgetHotel {...props} />
      :
        <Hotel {...props} />
      }
    </Provider>
  );
}

export default HotelApp;
