import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import { useSelector, useDispatch } from "react-redux";
import { filterModalStyles } from "./styles/styles";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Moment from "moment";
import I18n from "../../../i18n-js/index.js.erb";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import ListItem from "@material-ui/core/ListItem";
import Emoji from "a11y-react-emoji";
import axios from "axios";
import Search from "./Search";
import { Button, Col } from "reactstrap";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@mui/material/Collapse";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import { setFilterData } from "../../redux/actions/actions";
import clsx from "clsx";
import { parameterizeArray, parameterize } from "../../../helpers/base";

function FiltersModal(props) {
  const dispatch = useDispatch();
  const classes = filterModalStyles();

  const filtersData = useSelector((state) => state.filtersData);
  const currentLocale = useSelector((state) => state.currentLocale);
  const selectedPax = useSelector((state) => state.selectedPax);
  const filtersResults = useSelector((state) => state.filtersResults);
  const area = useSelector((state) => state.area);
  const areaHomeProducts = useSelector((state) => state.areaHomeProducts);
  const areaHomeHotelCategories = useSelector(
    (state) => state.areaHomeHotelCategories
  );
  const [selectedDate, setSelectedDate] = useState(
    props.available_date
  );
  const [walletFriendly, setWalletFriendly] = useState(
    props.wallet_friendly && props.wallet_friendly === "true"
      ? true
      : props.filterrific_params &&
        props.filterrific_params.wallet_friendly === "true"
      ? true
      : false
  );

  I18n.locale = currentLocale;
  Moment.locale(I18n.locale);

  const [isFocused, setIsFocused] = useState(false);
  const [focusedOnce, setFocusedOnce] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [expandedWho, setExpandedWho] = useState(false);
  const [adults, setAdults] = useState(
    props.adults
      ? props.adults
      : selectedPax && selectedPax.adults
      ? selectedPax.adults
      : 1
  );
  const [kids, setKids] = useState(
    props.kids
      ? props.kids
      : selectedPax && selectedPax.kids
      ? selectedPax.kids
      : 0
  );
  const [pax, setPax] = useState({
    adults: adults,
    kids: kids,
    /* babies: 0, */
    total: adults + kids,
  });
  const [results, setResults] = useState(props.resultsFilters);
  const [products, setProducts] = useState(
    props.products
      ? props.products
      : props.product_category
      ? parameterize(props.product_category)
      : props.filterrific_params && props.filterrific_params.products
      ? props.filterrific_params.products.toLowerCase()
      : props.filterrific_params &&
        props.filterrific_params.adults_only &&
        props.filterrific_params.adults_only == "%adults%"
      ? "adults-only"
      : props.filterrific_params && props.filterrific_params.accept_kids === ""
      ? "family-friendly"
      : props.porduct_category_name
      ? props.product_category_name
      : props.initial_product_param ? props.initial_product_param : []
  );

  const [hotelCategory, setHotelCategory] = useState(
    props.categories ? props.categories : []
  );

  const [priceLow, setPriceLow] = useState(props.price_low && props.price_low);
  const [priceHigh, setPriceHigh] = useState(
    props.price_high && props.price_high
  );

  const schema = yup.object({
    price_low: yup
      .number()
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .min(0),
    price_high: yup
      .number()
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .min(1),
    wallet_friendly: yup.boolean(),
  });

  const {
    register,
    handleSubmit,
    setError,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchAllFields = watch();

  const lessBtn = (color) => {
    return (
      <svg
        className={color === "grey" ? classes.blocked : classes.pointer}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="10" r="9" stroke={color} strokeWidth="2" />
        <line
          x1="6.59143"
          y1="10"
          x2="13.4086"
          y2="10"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    );
  };

  const moreBtn = (color) => {
    return (
      <svg
        className={color === "grey" ? classes.blocked : classes.pointer}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="10" r="9" stroke={color} strokeWidth="2" />
        <line
          x1="6.59143"
          y1="9.75269"
          x2="13.4086"
          y2="9.75269"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M9.89246 12.8817V6.88171"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    );
  };

  const slug = (slug) => {
    switch (slug) {
      case "most-popular":
        return "experiences?orderby=mostPopular";
      case "most-recent":
        return "experiences?orderby=mostRecent";
      case "family-friendly":
        return "family-friendly";
      case "all-inclusive":
        return "all-inclusive";
      case "adults-only":
        return `adults-only`;
      case "recommended":
        return;
      case "recommended_south":
        return;
      case "recommended_north":
      default:
        return slugify(I18n.t(slug));
    }
  };

  const handleIsBlockedDays = (momentDate) => {
    const blockDayRange = momentDate > Moment(new Date()).add(90, "days");
    const blockCategory =
      momentDate.format("YYYY-MM-DD") === Moment().format("YYYY-MM-DD") &&
      props.todayNotAvailable;
    const blockTodayAfterTime =
      momentDate.format("YYYY-MM-DD") === Moment().format("YYYY-MM-DD") &&
      Moment().format("HH") >= 12;
    return blockDayRange || blockCategory || blockTodayAfterTime;
  };

  const handleDatePickerFocus = () => {
    if (!focusedOnce) {
      setFocusedOnce(true);
    } else {
      setFocusedOnce(false);
    }

    setIsFocused(!isFocused);
  };

  useEffect(() => {
    props.externalSubmit &&
      handleSubmit(onSubmit)()
  }, [props.externalSubmit])

  const handleSelectedDate = (date) => {
    setIsFocused(!isFocused);

    setSelectedDate(date);

    !props.areaHome && dispatch(setSelectedDate(date));
  };

  const handleCategory = (cat) => {
    console.log('cat', cat)

    var selectedProducts = "";
    switch (typeof products) {
      case "string":
        selectedProducts = [];
        selectedProducts = selectedProducts.concat(products.split(","));
        break;
      case "object":
        selectedProducts = products;
        break;
      case "undefined":
        selectedProducts = [];
        break;
      default:
        selectedProducts = [];
        selectedProducts.push(products);
    }

    const index = selectedProducts.indexOf(cat);
    if (index > -1) {
      selectedProducts.splice(index, 1);
    } else {
      selectedProducts.push(cat);
    }

    setProducts(selectedProducts);
  };

  const handleHotelCategory = (cat) => {
    var selectedCategories = "";

    switch (typeof hotelCategory) {
      case "string":
        selectedCategories = [];
        selectedCategories = selectedCategories.concat(
          hotelCategory.split(",")
        );
        break;
      case "object":
        selectedCategories = hotelCategory;
        break;
      case "undefined":
        selectedCategories = [];
        break;
      default:
        selectedCategories = [];
        selectedCategories.push(hotelCategory);
    }

    const index = selectedCategories.indexOf(cat);
    if (index > -1) {
      selectedCategories.splice(index, 1);
    } else {
      selectedCategories.push(cat);
    }

    setHotelCategory(selectedCategories);
  };

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const handleExpandWho = () => {
    setExpandedWho(!expandedWho);
  };

  const handleMorePax = (type) => {
    switch (type) {
      case "adults":
        setPax({ ...pax, adults: pax[type] + 1, total: pax["total"] + 1 });
        break;
      case "kids":
        setPax({ ...pax, kids: pax[type] + 1, total: pax["total"] + 1 });
        break;
      case "babies":
        setPax({ ...pax, babies: pax[type] + 1, total: pax["total"] + 1 });
        break;
      default:
        break;
    }
  };

  const handleLessPax = (type) => {
    switch (type) {
      case "adults":
        setPax({ ...pax, adults: pax[type] - 1, total: pax["total"] - 1 });
        break;
      case "kids":
        setPax({ ...pax, kids: pax[type] - 1, total: pax["total"] - 1 });
        break;
      case "babies":
        setPax({ ...pax, babies: pax[type] - 1, total: pax["total"] - 1 });
        break;
      default:
        break;
    }
  };

  const hotelCategoriesListed = () => {
    return areaHomeHotelCategories.payload.map(function (category) {
      return (
        <>
          {
            <ListItem
              key={category}
              className={classes.buttonText}
              onClick={() => handleHotelCategory(category)}
            >
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name={category}
                  id={category}
                  {...register(`${category}`)}
                  checked={hotelCategory.includes(category)}
                />
                <span style={{ flexGrow: 4, paddingLeft: "10px" }}>
                  {I18n.t(category.toLowerCase())}
                </span>
              </div>
            </ListItem>
          }
        </>
      );
    });
  };

  const whatCategoriesListed = () => {
    var whatCategoriesListedResult = areaHomeProducts.filters.what.map(
      function (category, index) {
        return (
          <>
            {category.slug.includes("recommended") ? null : (
              <>
                {index < 5 ? (
                  <ListItem
                    key={category.slug}
                    className={classes.buttonText}
                    onClick={() => handleCategory(category.slug)}
                  >
                    <div className="checkbox-container">
                      <input
                        type="checkbox"
                        name={category.slug}
                        id={category.slug}
                        {...register(`${category.slug}`)}
                        checked={products.includes(category.slug) || products.includes(category.title)}
                      />
                      <Emoji
                        symbol={category.emoji}
                        className={classes.emojis}
                      />
                      <span style={{ flexGrow: 4, paddingLeft: "10px" }}>
                        {category.title}
                      </span>
                    </div>
                  </ListItem>
                ) : (
                  <React.Fragment>
                    <Collapse in={expanded}>
                      <ListItem
                        key={category.slug}
                        className={classes.buttonText}
                        onClick={() => handleCategory(category.slug)}
                      >
                        <div className="checkbox-container">
                          <input
                            type="checkbox"
                            name={category.slug}
                            id={category.slug}
                            {...register(`${category.slug}`)}
                            checked={products.includes(category.slug) || products.includes(category.title)}
                          />
                          <Emoji
                            symbol={category.emoji}
                            className={classes.emojis}
                          />
                        </div>
                        <span style={{ flexGrow: 4, paddingLeft: "10px" }}>
                          {category.title}
                        </span>
                      </ListItem>
                    </Collapse>
                  </React.Fragment>
                )}
              </>
            )}
          </>
        );
      }
    );

    return whatCategoriesListedResult;
  };

  const WhoCategoriesListed = () => {
    var whotCategoriesListedResult = areaHomeProducts.filters.who.map(function (
      category,
      index
    ) {
      return (
        <>
          {category.slug.includes("recommended") ? null : (
            <>
              {index < 5 ? (
                <ListItem
                  className={classes.buttonText}
                  onClick={() => handleCategory(category.slug)}
                >
                  <div className="checkbox-container">
                    <input
                      name={category.slug}
                      id={category.slug}
                      type="checkbox"
                      {...register(`${category.slug}`)}
                      checked={products.includes(category.slug) || products.includes(category.title)}
                    />
                    <Emoji symbol={category.emoji} className={classes.emojis} />
                  </div>
                  <span
                    htmlFor={category.slug}
                    style={{ flexGrow: 4, paddingLeft: "10px" }}
                  >
                    {category.title}
                  </span>
                </ListItem>
              ) : (
                <React.Fragment>
                  <Collapse in={expandedWho}>
                    <ListItem
                      className={classes.buttonText}
                      onClick={() => handleCategory(category.slug)}
                    >
                      <div className="checkbox-container">
                        <input
                          type="checkbox"
                          id={category.slug}
                          name={category.slug}
                          {...register(`${category.slug}`)}
                          checked={products.includes(category.slug) || products.includes(category.title)}
                        />
                        <Emoji
                          symbol={category.emoji}
                          className={classes.emojis}
                        />
                      </div>
                      <span style={{ flexGrow: 4, paddingLeft: "10px" }}>
                        {category.title}
                      </span>
                    </ListItem>
                  </Collapse>
                </React.Fragment>
              )}
            </>
          )}
        </>
      );
    });

    return whotCategoriesListedResult;
  };

  const whoCategoriesExpand = () => {
    var whoCategoriesExpandResult = areaHomeProducts.filters.who.length > 5 && (
      <span onClick={handleExpandWho} className={classes.viewAllBtn}>
        {expandedWho ? (
          <>
            {I18n.t("view_less")}{" "}
            <svg
              width="11"
              height="7"
              viewBox="0 0 11 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10 6L5.5 1L1 6" stroke="black" />
            </svg>
          </>
        ) : (
          <>
            {I18n.t("view_more")}{" "}
            <svg
              width="11"
              height="7"
              viewBox="0 0 11 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L5.5 6L10 1" stroke="black" />
            </svg>
          </>
        )}
      </span>
    );

    return whoCategoriesExpandResult;
  };

  const whatCategoriesExpand = () => {
    var whatCategoriesExpandResult = areaHomeProducts.filters.what.length >
      5 && (
      <span onClick={() => handleExpand()} className={classes.viewAllBtn}>
        {expanded ? (
          <>
            {I18n.t("view_less")}{" "}
            <svg
              width="11"
              height="7"
              viewBox="0 0 11 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10 6L5.5 1L1 6" stroke="black" />
            </svg>
          </>
        ) : (
          <>
            {I18n.t("view_more")}{" "}
            <svg
              width="11"
              height="7"
              viewBox="0 0 11 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L5.5 6L10 1" stroke="black" />
            </svg>
          </>
        )}
      </span>
    );

    return whatCategoriesExpandResult;
  };

  const handleOnClose = () => {
    dispatch(setFilterData({ ...filtersData, form: { ...watchAllFields } }));

    props.onClose();
  };

  const onSubmit = (data) => {
    const params = [];
    products && products.length > 0 && params.push(`product=${products}`);

    hotelCategory &&
      hotelCategory.length > 0 &&
      params.push(`category=${hotelCategory}`);

    Object.keys(pax).forEach(function (key) {
      params.push(`${key}=${pax[key]}`);
    });

    params.push(`province=${area}`);

    !props.externalSubmit && selectedDate && params.push(`date=${selectedDate}`);

    props.externalSubmit && props.available_date && params.push(`date=${props.available_date}`);

    Object.keys(data).forEach(function (key) {
      data[key] != undefined && params.push(`${key}=${data[key]}`);
    });

    const url_params = params.join("&");

    window.open(
      `${window.location.origin}/${I18n.locale}/${props.source}?${url_params}`,
      "_blank"
    );
  };

  useEffect(() => {
    let isMounted = true;

    if (!props.alreadyFiltersResults && isMounted && area) {
      axios
        .get("/api/main/filters.json", {
          params: {
            area: area,
          },
        })
        .then((response) => {
          if (isMounted) {
            setResults(response.data.results);
          }
        })
        .then(() => {
          reset({
            ...filtersData.form,
          });
        });
    }

    return () => {
      isMounted = false;
    };
  }, [area]);

  return (
    <React.Fragment>
      <Drawer
        open={filtersData.open}
        anchor={"right"}
        onClose={() => handleOnClose()}
        aria-labelledby="Filtros"
        aria-describedby="Filtros"
        scroll={"body"}
        fullwidth
        sx={{
          width: "100%",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>
            {I18n.t("hotel_search__filter")}
            <IconButton
              className={classes.closeButton}
              edge="end"
              color="inherit"
              onClick={() => handleOnClose()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent classes={{ root: classes.overFlowOverride }}>
            {!props.alreadyFiltersResults &&
              results &&
              results != undefined && (
                <>
                  <p className={classes.sectionTitle}>
                    {I18n.t("search_hotelbreak")}
                  </p>
                  <Search results={results} />
                </>
              )}

            {props.alreadyFiltersResults &&
              filtersResults &&
              filtersResults != undefined && (
                <>
                  <p className={classes.sectionTitle}>
                    {I18n.t("search_hotelbreak")}
                  </p>
                  <Search results={filtersResults} />
                </>
              )}

            <br />
            <p className={classes.sectionTitle}>
              {I18n.t("guidelist_category")}
            </p>
            {filtersData.open &&
              areaHomeProducts.status &&
              whatCategoriesListed()}
            {filtersData.open &&
              areaHomeProducts.status &&
              whatCategoriesExpand()}
            <p className={classes.sectionTitle}>{I18n.t("select_date")}</p>

            <SingleDatePicker
              date={selectedDate ? Moment(selectedDate) : null}
              onDateChange={(date) =>
                handleSelectedDate(date.format("YYYY-MM-DD"))
              }
              id="dateSelector"
              focused={isFocused}
              onFocusChange={() => handleDatePickerFocus()}
              isDayBlocked={(momentDate) => handleIsBlockedDays(momentDate)}
              numberOfMonths={1}
              firstDayOfWeek={1}
              readOnly={true}
              displayFormat={"ll"}
              small
              hideKeyboardShortcutsPanel
              keepOpenOnDateSelect={false}
              customInputIcon={"🗓"}
              placeholder={I18n.t("select_date")}
            />

            <br />
            <p className={classes.sectionTitle}>{I18n.t("pax__button")}</p>

            <div>
              <div className={clsx(classes.paxInnerSelect, classes.grow4)}>
                <p>{I18n.t("adults")}</p>
                <div
                  className={clsx(classes.paxInnerSelect, classes.paxSelector)}
                >
                  <span
                    onClick={
                      pax["adults"] > 0
                        ? () => handleLessPax("adults")
                        : undefined
                    }
                  >
                    {lessBtn(pax["adults"] <= 0 ? "grey" : "black")}
                  </span>
                  {pax["adults"]}
                  <span
                    onClick={
                      pax["adults"] < 10
                        ? () => handleMorePax("adults")
                        : undefined
                    }
                  >
                    {moreBtn(pax["adults"] >= 10 ? "grey" : "black")}
                  </span>
                </div>
              </div>
              <div className={classes.paxInnerSelect}>
                <p>{I18n.t("kids")}</p>
                <div
                  className={clsx(classes.paxInnerSelect, classes.paxSelector)}
                >
                  <span
                    onClick={
                      pax["kids"] > 0 ? () => handleLessPax("kids") : undefined
                    }
                  >
                    {lessBtn(pax["kids"] <= 0 ? "grey" : "black")}
                  </span>
                  {pax["kids"]}
                  <span
                    onClick={
                      pax["kids"] < 10 ? () => handleMorePax("kids") : undefined
                    }
                  >
                    {moreBtn(pax["kids"] >= 10 ? "grey" : "black")}
                  </span>
                </div>
              </div>
            </div>

            <br />
            <p className={classes.sectionTitle}>
              {I18n.t("hotel_search__price_per_person")}
            </p>
            <Col className="col-md-6 col-xs-5 no-padding-6-l">
              <input
                type="number"
                {...register("price_low")}
                name="price_low"
                placeholder="Min"
                defaultValue={priceLow}
                className="form-control"
              />
            </Col>
            <Col className="col-md-offset-0 col-xs-offset-2 col-md-6 col-xs-5 no-padding-6-r">
              <input
                type="number"
                {...register("price_high")}
                name="price_high"
                placeholder="Max"
                className="form-control"
                defaultValue={priceHigh}
              />
              {errors.price_high?.message}
            </Col>
            <br />
            <hr className="col-md-11" />
            <br />
            <Col className="no-padding-6-l col-xs-10 col-md-10">
              <span>{I18n.t("wallet_friendly")}</span>
              <br />
              <small>{I18n.t("wallet_friendly_explain")}</small>
            </Col>
            <Col className="no-padding-6-r col-md-2 col-xs-2">
              <label className="switch">
                <input
                  type="checkbox"
                  name="wallet_friendly"
                  {...register("wallet_friendly")}
                  value="true"
                  onClick={() => setWalletFriendly(!walletFriendly)}
                  checked={walletFriendly}
                />
                <span className="slider round"></span>
              </label>
            </Col>
            <br />
            <hr className="col-md-11" />
            <br />
            {filtersData.open && areaHomeProducts.status && (
              <p className={classes.sectionTitle}>{I18n.t("who_for")}</p>
            )}
            {filtersData.open &&
              areaHomeProducts.status &&
              WhoCategoriesListed()}
            {filtersData.open &&
              areaHomeProducts.status &&
              whoCategoriesExpand()}
            {filtersData.open &&
              areaHomeProducts.status &&
              areaHomeHotelCategories.payload && (
                <p className={classes.sectionTitle}>{I18n.t("hotel_type")}</p>
              )}
            {filtersData.open &&
              areaHomeProducts.status &&
              areaHomeHotelCategories.payload &&
              hotelCategoriesListed()}
          </DialogContent>
          <div style={{ clear: "both" }} />
          <DialogActions>
            <Button type="submit" className={classes.fullWidth}>
              {I18n.t("pax__modal_validate")}
            </Button>
          </DialogActions>
        </form>
      </Drawer>
    </React.Fragment>
  );
}

export default FiltersModal;
