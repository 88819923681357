import React, { useState, useEffect } from "react";
import axios from "axios";
import I18n from "../i18n-js/index.js.erb";
import { t } from "i18n-js";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "./hooks/useMediaQuery";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Col,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input,
  Form,
  FormGroup,
  Label,
  Button,
} from "reactstrap";

function Login(props) {
  const isMobile = useMediaQuery("(max-width: 764px)");

  const schema = yup.object({
    email: yup
      .string()
      .email(I18n.t("reservation_email_format"))
      .required(I18n.t("is_required")),
    password: yup.string().required(I18n.t("is_required")),
  });

  let error = {
    color: "red",
    borderColor: "red",
  };

  let height = {
    height: isMobile ? "25vh" : "100vh",
  };

  axios.defaults.headers.common["X-CSRF-Token"] = props.csrf_token;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [message, setMessage] = useState();

  useEffect(() => {
    document.title = "Login | hotelbreak";
  }, []);

  const onSubmit = (data) => {
    axios
      .post("../../api/v1/users/signin.json", { user: { ...data } })
      .then((response) => {
        if (response.data.table.success === true) {
          window.location.href = response.data.table.redirect_url;
        } else {
          setMessage(response.data.table.user);
        }
      })
      .catch(function (error) {});
  };

  I18n.locale = props.current_locale;
  return (
    <div className="container-fluid">
      <div className="row no-gutter no_bottom">
        <div
          className="d-none d-md-flex col-md-4 col-lg-6 bg-image"
          style={height}
        ></div>
        <div className="col-md-8 col-lg-6">
          <div className="login d-flex align-items-center py-5">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-12 mx-auto">
                  <div style={{ height: "20vh" }}></div>
                  <h3 className="login-heading mb-4">Dashboard login</h3>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-label-group">
                      <Label htmlFor="inputEmail">{I18n.t("form_email")}</Label>
                      <input
                        type="email"
                        id="inputEmail"
                        name="user[email]"
                        className="form-control"
                        placeholder={I18n.t("form_email")}
                        required
                        {...register("email")}
                        autoFocus
                      />
                      <p className="error">{errors.email?.message}</p>
                    </div>

                    <div className="form-label-group">
                      <Label htmlFor="inputPassword">
                        {I18n.t("form_password")}
                      </Label>
                      <input
                        type="password"
                        id="inputPassword"
                        name="user[password]"
                        className="form-control"
                        placeholder={I18n.t("form_password")}
                        {...register("password")}
                        required
                      />
                      <p className="error">{errors.password?.message}</p>
                    </div>

                    <Button
                      className="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold mb-2"
                      type="submit"
                    >
                      {I18n.t("login")}
                    </Button>
                    <div className="text-center">
                      <a
                        className="small"
                        href="https://www.hotelbreak.com/password/new"
                      >
                        Forgot password?
                      </a>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
