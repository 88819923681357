import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import AreaHomeWrap from "../components/main/AreaHomeWrap";
import { useSelector, useDispatch } from "react-redux";
import {
  addCSRFToken,
  setCurrentUser,
  changeSelectedArea,
  changeSelectedAreaName,
  changeLocale,
  setAreaHomeProducts,
  setAreaHomeHotelCategories,
} from "./redux/actions/actions";
import I18n from "../i18n-js/index.js";
import { capitalizeFirstLetter } from "../helpers/base";
import UserLoginWrap from "./main/components/modals/UserLogin/UserLoginWrap";
import { createMedia } from "@artsy/fresnel";
import { StaticRouter } from "react-router-dom";

function Main(props) {
  const dispatch = useDispatch();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(true);
  }, []);

  if (props.current_user && props.wallet && !ready) {
    dispatch(
      setCurrentUser({
        ...props.current_user,
        wallet: props.wallet,
        pending_review: props.pending_review
      })
    );
    dispatch(changeLocale(props.current_user.user_locale));
  }
  else {
    dispatch(changeLocale(props.locale));
  }

  const selectedArea = useSelector((state) => state.area);
  const currentLocale = useSelector((state) => state.currentLocale);
  const csrfToken = useSelector((state) => state.csrfToken);
  const selectedAreaName = useSelector((state) => state.areaName);
  const selectedDate = useSelector((state) => state.selectedDate);
  const selectedPax = useSelector((state) => state.selectedPax);
  const [hasProvince, setHasProvince] = useState(
    props.area_cookie ? true : false
  );
  const [reloaded, setReloaded] = useState();

  I18n.locale = currentLocale;

  dispatch(addCSRFToken(props.csrf_token));

  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;

  useEffect(() => {
    setReloaded(false);
    if (props.area_cookie && selectedArea) {
      dispatch(changeSelectedArea(selectedArea));
      var location_param = selectedArea;
    } else if (props.area_cookie && !selectedArea) {
      dispatch(changeSelectedArea(props.area_cookie));
      var location_param = props.area_cookie;
    }

    axios
      .get("/api/main/products", {
        params: {
          location: location_param,
          locale: currentLocale,
          available_date: selectedDate,
          selected_pax: selectedPax,
        },
      })
      .then((response) => {
        dispatch(
          setAreaHomeProducts(
            response.data.area_home_products
              .map((product_list) => {
                return {
                  category: product_list.products.map(
                    (productSerialization) => productSerialization.attributes
                  ),
                  title: product_list.title,
                  slug: product_list.slug,
                  emoji: product_list.emoji,
                  popularity: product_list.popularity,
                  type: product_list.type,
                  category_description: product_list.category_description
                };
              })
              .sort((a, b) => {
                if (a.popularity > b.popularity) {
                  return -1;
                } else if (
                  a.popularity === "most_popular" ||
                  a.popularity === "most_recent"
                ) {
                  return -1;
                } else {
                  return 1;
                }
              })
          )
        );
        setHasProvince(true);
        dispatch(setAreaHomeHotelCategories(response.data.rooms_categories));
        dispatch(changeSelectedAreaName(response.data.province));
        setReloaded(true);
      })
      .catch(function (error) {
        setHasProvince(false);
      });

    selectedAreaName
      ? (document.title =
          I18n.t("daycation_in") +
          " " +
          capitalizeFirstLetter(selectedAreaName) +
          " | hotelbreak")
      : (document.title = I18n.t("home_title") + " | hotelbreak");
    
    props.brand && props.brand.name != 'Widget' &&
        (document.title = `${props.brand.name} x hotelbreak`)
  }, [selectedArea, selectedDate, selectedPax]);

  const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      sm: 0,
      md: 768,
      lg: 1024,
      xl: 1192,
    },
  });

  return (
    <React.Fragment>
      <MediaContextProvider>
        <Media at="sm">
          <UserLoginWrap fullscreen={true} />
        </Media>

        <Media at="md">
          <UserLoginWrap fullscreen={false} />
        </Media>

        <Media greaterThanOrEqual="lg">
          <UserLoginWrap fullscreen={false} />
        </Media>
      </MediaContextProvider>

      <StaticRouter>
        <AreaHomeWrap
          area_cookie={props.area_cookie}
          reloaded={reloaded}
          selectedDate={selectedDate}
          selectedPax={selectedPax}
          hasProvince={hasProvince}
          todayNotAvailable={props.today_not_available}
          brand={props.brand}
        />
      </StaticRouter>
    </React.Fragment>
  );
}

export default Main;
