import React from "react";
import { useState, useEffect } from "react";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { useStylesPayment } from "./styles/styles";
import I18n from "../../../i18n-js/index.js.erb";
import HotelBreakLoader from "../components/loaders/hotelBreakLoader";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { setStepsVoucherCreationParams } from "../../redux/actions/actions";
import Preview from "../vouchers/steps/step5/Preview";
import loadingLogo from "images/logo_reduced.png";

const schema = yup.object({
  resevation_conditions: yup.boolean().oneOf([true], I18n.t("is_required")),
});

let conditionsCheck = {
  float: "left",
};

let conditionsLabel = {
  marginTop: "2px",
  float: "left",
  width: "calc(100% - 30px)",
}

function Payment() {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [price, setPrice] = useState();
  const [elementLoad, setElementLoad] = useState(true);
  const [cardError, setCardError] = useState(false);
  const [loading, setLoading] = useState(false);

  const clientSecret = useSelector((state) => state.clientSecret);
  const currentLocale = useSelector((state) => state.currentLocale);
  const currentUser = useSelector((state) => state.currentUser);

  const currentCountry = currentUser?.isInformationComplete?.country_code ? currentUser.isInformationComplete.country_code : 'ES'

  const paymentElementOptions = {
    defaultValues: {
      billingDetails: {
        address: {
          country: currentCountry
        }
      }
    }
  }

  const stepsVoucherCreationParams = useSelector(
    (state) => state.stepsVoucherCreationParams
  );

  const {
    register,
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const classes = useStylesPayment();

  useEffect(() => {
    setTimeout(() => {
      setElementLoad(false);
    }, 1500);
    setPrice(
      stepsVoucherCreationParams.step2.evoucher
        ? stepsVoucherCreationParams.step1.price + 3
        : stepsVoucherCreationParams.step1.price + 7
    );
  }, [stripe]);

  useEffect(() => {
    if (!stepsVoucherCreationParams.step6.status) {
      axios
        .post("/api/main/tracking/checkouts/viewed.json", {
          params: { step: 6, step_name: "payment" },
        })
        .then((response) => {
          console.log(response.status);
        })
        .catch(function (error) { });
    }
  }, []);

  const onSubmit = (event) => {
    Object.keys(errors).length === 0 && initPayment(event);
  };

  const initPayment = async (event) => {
    setLoading(true)

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      setLoading(false)
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret,
      redirect: "if_required",
    });

    if (error) {
      setLoading(false)

      dispatch(
        setStepsVoucherCreationParams({
          ...stepsVoucherCreationParams,
          step6: {
            status: true,
          },
        })
      );
    } else {

      dispatch(
        setStepsVoucherCreationParams({
          ...stepsVoucherCreationParams,
          step6: {
            status: true,
          },
        })
      );

      axios
        .post("/api/main/tracking/checkouts/completed.json", {
          params: { step: 6, step_name: "payment" },
        })
        .then((response) => {
          console.log(response.status);
        })
        .catch(function (error) {
          setLoading(false)
        });

      window.location.replace(
        `/${currentLocale}/checkout?payment_intent=${stepsVoucherCreationParams.step5.payment_intent
        }&payment_intent_client_secret=${clientSecret}redirect_status=succeeded`
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {elementLoad ? (
        <HotelBreakLoader />
      ) : (
        <>
          <row style={{ marginBottom: "20px" }}>
            <div className="col-md-12 col-xs-12 no-padding-6-l">
              <div className="col-md-6 col-xs-12 no-padding-6-l">
                <Preview />
              </div>
              <div className="col-md-6 col-xs-12">
                <strong className={classes.resumeTitle}>{I18n.t("gift_card__resumen")}</strong>
                <p>{I18n.t("gift_card__price")} {price - 3}€</p>
                <p className={classes.toolstip}>
                  {I18n.t("gift_card__fee")}{" "}3€
                  <span className={classes.toolstiptext}>
                    {I18n.t("payments_summary__overhead_desc")}
                  </span>
                </p>
                <p>{I18n.t("gift_card__total")} {price}€</p>
              </div>
            </div>
            <div className="col-md-12 col-xs-12" style={{ marginTop: 40 }}>

              <div>

              </div>

              <PaymentElement id="payment-element" options={paymentElementOptions}
                onBlur={() => analyticsTrack("Filled CardElement", {})}
                onFocus={() => analyticsTrack("Entered CardElement", {})}
                style={{ height: "auto !important", padding: "0 !important" }} />
              {cardError && <p className={classes.error}>{cardError}</p>}

              <br />

              <input
                type="checkbox"
                name="reservation_conditions"
                {...register("resevation_conditions")}
                id="reservation_conditions"
                style={conditionsCheck}
                className={clsx(
                  "form-control",
                  errors.resevation_conditions && "error"
                )}
              />
              <label
                htmlFor={"reservation_conditions"}
                style={conditionsLabel}
                className={clsx(errors.resevation_conditions && "error")}
                dangerouslySetInnerHTML={{
                  __html: I18n.t("condiciones_checkout_html"),
                }}
              />

              {loading && <div className="loading__container">
                <img
                  className="loading__hotelbreak"
                  alt={"Loading logo"}
                  src={loadingLogo}
                  data-original={loadingLogo}
                />
              </div>}

              {!loading && <button
                className={clsx("btn btn-primary", classes.paymentButton)}
                disabled={!stripe}
              >
                {I18n.t("pay_button", {
                  price: price,
                  currency: "€",
                })}
              </button>}
            </div>
          </row>
        </>
      )}
    </form>
  );
}

export default Payment;
