import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Emoji from "a11y-react-emoji";
import { createMedia } from "@artsy/fresnel";
import { capitalizeFirstLetter } from "../../../helpers/base.js";
import ProductOnDemandForm from "./ProductOnDemandForm";
import Skeleton from "@material-ui/lab/Skeleton";
import clsx from "clsx";
import ReviewBlock from "../reviews/ReviewBlock";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import ReviewSlide from "../reviews/ReviewSlide";
import SubscriptionForm from "../components/subscriptions/SubscriptionForm";
import { useSelector, useDispatch } from "react-redux";
import { setAvailabilities } from "../../redux/actions/actions";
import ProductBookingForm from "./ProductBookingForm";
import I18n from "../../../i18n-js/index.js.erb"

function ProductModal(props) {
  const dispatch = useDispatch();

  const area = props.area;
  const hotel = props.hotel;
  const product = props.product;

  const selectedArea = capitalizeFirstLetter(
    useSelector((state) => state.area)
  );

  const currentLocale = useSelector((state) => state.currentLocale);
  const csrfToken = useSelector((state) => state.csrfToken);

  const [experience, setExperience] = useState();
  const [totalPrice, setTotalPrice] = useState();
  const [otherProducts, setOtherProducts] = useState();
  const [otherProductsHotel, setOtherProductsHotel] = useState();
  const [reviews, setReviews] = useState();
  const [reviewCount, setReviewCount] = useState();
  const [averageReview, setAverageReview] = useState();
  const [openReviews, setOpenReviews] = useState(false);
  const [directBooking, setDirectBooking] = useState(undefined);
  const [bookable, setBookable] = useState(undefined);
  const [onDemand, setOnDemand] = useState(undefined);
  const [acceptKids, setAcceptKids] = useState();
  const [metro, setMetro] = useState();
  const [hotelStatus, setHotelStatus] = useState();
  const currentAvailabilities = useSelector(
    (state) => state.currentAvailabilities
  );

  const handleReviewsOpen = () => {
    setOpenReviews(true);
  };

  const handleReviewsClose = () => {
    setOpenReviews(false);
  };

  const StyledDialog = withStyles({
    paper: {
      margin: "7rem 0 0 0",
      borderRadius: "1.8rem",
    },
    paperScrollPaper: {
      maxHeight: "80vh",
    },
  })(Dialog);

  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;

  useEffect(() => {
    axios
      .get("/api/main/products/show.json", {
        params: {
          slug: props.product,
          area: props.area,
          hotel: props.hotel,
          locale: currentLocale,
          modal: true,
        },
      })
      .then((response) => {
        dispatch(setAvailabilities(response.data.currentAvailabilities));
        setExperience(response.data.experience.data);
        setTotalPrice(response.data.experience.data.attributes.pvp);
        setHotelStatus(response.data.experience.data.attributes.hotelStatus);
        setOtherProducts(response.data.other_in_area);
        setOtherProductsHotel(response.data.other_in_hotel);
        setDirectBooking(response.data.direct_booking);
        setBookable(response.data.bookable);
        setOnDemand(response.data.experience.data.attributes.onDemand);
        setReviews(response.data.reviews);
        setReviewCount(response.data.review_count);
        setAverageReview(response.data.average_review);
        setAcceptKids(
          response.data.experience.data.attributes.productTickets.accept_kids
        );
        setMetro(response.data.experience.data.attributes.metro);
        document.title =
          response.data.experience.data.attributes.name +
          " | " +
          response.data.experience.data.attributes.hotel +
          " | " +
          selectedArea +
          " | hotelbreak";
      });
  }, [product]);

  const useStyles = makeStyles((theme) => ({
    experienceImage: {
      maxWidth: "100%",
      width: "100%",
      minHeight: "290px",
      top: "0",
      zIndex: "-1",
    },
    experienceImageLarge: {
      width: "60vw",
      zIndex: 7,
      objectFit: "cover",
      position: "static",
      maxWidth: "49vw",
      height: "470px",
      borderRadius: "10px",
      marginLeft: "150px",
      boxShadow: "rgb(0 0 0 / 25%) 0px 2px 4px",
    },
    backgroundImage: {
      filter: "blur(15px)",
      zIndex: -1,
      position: "absolute",
      width: "100%",
      top: "-20px",
    },
    slidingPane: {
      marginTop: "-20px",
      paddingTop: "5px",
      borderTopLeftRadius: "20px",
      borderTopRightRadius: "20px",
      backgroundColor: "white",
      width: "100%",
      height: "fit-content",
      position: "relative",
      boxShadow: "0 -2px 4px rgba(0,0,0,0.25)",
    },
    slidingPaneLarge: {
      width: "100%",
      height: "fit-content",
      boxShadow: "0 -2px 4px rgb(0 0 0 / 25%)",
      marginTop: "-120px",
      paddingTop: "5px",
      backgroundColor: "white",
      borderTopLeftRadius: "20px",
      borderTopRightRadius: "20px",
    },
    acceptKids: {
      marginTop: "-230px !important",
    },
    title: {
      fontSize: "18px",
      fontWeight: "500 !important",
    },
    titleLarge: {
      fontSize: "20px",
      fontWeight: "500 !important",
    },
    divider: {
      height: "6px",
      width: "100%",
      backgroundColor: "#ECECEC",
      marginBottom: "10px",
    },
    hotelName: {
      paddingTop: "10px",
      marginBottom: "-15px",
    },
    hotelNameLarge: {
      fontSize: "20px",
      paddingTop: "110px",
      marginbottom: "-15px",
    },
    largeContainer: {
      marginLeft: "10%",
      maxWidth: "50%",
    },
    container: {
      margin: "20px 30px",
    },
    hotelSlider: {
      margin: "10px 30px",
    },
    hotelLink: {
      fontSize: "18px",
      fontWeight: "800",
    },
    secondaryTitle: {
      fontSize: "18px",
      fontWeight: "500 !important",
    },
    formBookingLarge: {
      borderRadius: "10px",
      width: "25%",
      minHeight: "49rem",
      position: "sticky",
      zIndex: "10",
      marginTop: "-470px",
      background: "white",
      top: "56px",
      marginLeft: "65%",
      padding: "40px",
      boxShadow: "rgb(0 0 0 / 25%) 0px 2px 4px",
    },
    formBookingLargeAcceptKids: {
      borderRadius: "10px",
      width: "25%",
      minHeight: "61rem",
      position: "sticky",
      zIndex: "10",
      marginTop: "-470px",
      background: "white",
      top: "56px",
      marginLeft: "65%",
      padding: "40px",
      boxShadow: "rgb(0 0 0 / 25%) 0px 2px 4px",
    },
    infoBlock: {
      padding: "20px",
      border: "1px solid lightgrey",
      borderRadius: "5px",
      height: "fit-content",
      padding: "15px",
    },
    horizontalEelements: {
      display: "flex",
      justifyContent: "space-between",
    },
    infoPhoto: {
      width: "100%",
      borderRadius: "5px",
    },
    sameLineEmoji: {
      display: "inline-block",
      marginRight: "1rem",
      fontSize: "2rem",
    },
    photoDialogBlock: {
      marginBottom: "2rem",
    },
    dialogTitle: {
      fontSize: "2rem",
      fontWeight: "300",
    },
    secondaryTitle: {
      fontSize: "22px",
      fontWeight: "500 !important",
    },
    sameLineText: {
      display: "inline-block",
      marginRight: "1rem",
    },
    paddingTop: {
      paddingTop: "2rem",
    },
    reviewBlock: {
      background: "rgb(234,237,235)",
      clear: 'both'
    },
  }));

  const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      sm: 0,
      md: 768,
      lg: 1024,
      xl: 1192,
    },
  });

  const classes = useStyles();

  const ProductMobile = (cols) => {
    return (
      <>
        <div>
          {experience != undefined ? (
            <img
              className={classes.experienceImage}
              src={
                "https://www.hotelbreak.com/" + experience.attributes.blockPhoto
              }
            />
          ) : (
            <Skeleton variant="rect" className={classes.experienceImage} />
          )}
          <div className={classes.slidingPane}>
            <div className={classes.container}>
              {experience != undefined ? (
                <p className={classes.hotelName}>
                  <Emoji symbol="🏨" />
                  {" " + experience.attributes.hotel}
                </p>
              ) : (
                <Skeleton variant="text" />
              )}
              {experience != undefined ? (
                <h1 className={classes.title}>{experience.attributes.name}</h1>
              ) : (
                <Skeleton variant="text" />
              )}
              <br />
              {experience != undefined ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: experience.attributes.description,
                  }}
                ></div>
              ) : (
                <>
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </>
              )}
              <br />
              <br />
            </div>
            <div className={classes.divider} />

            <div className={classes.container}>
              {onDemand !== undefined &&
                directBooking !== undefined &&
                directBooking &&
                !onDemand &&
                (hotelStatus &&
                  Object.keys(currentAvailabilities.availabilities).length > 0 &&
                  bookable) &&
                (
                  <ProductBookingForm
                    experience={experience}
                    hotel={props.hotel}
                    product={props.product}
                    area={props.area}
                    modal={props.modal}
                    locale={props.locale}
                    style={"inline"}
                  />
                )}

              {onDemand !== undefined &&
                directBooking !== undefined &&
                (onDemand || !directBooking) &&
                (hotelStatus && Object.keys(currentAvailabilities.availabilities).length > 0) &&
                bookable &&
                <ProductOnDemandForm experience={experience} />
              }

              {
                (bookable !== undefined && !bookable) &&
                <SubscriptionForm hotel={hotel} full={false} avails={Object.keys(currentAvailabilities.availabilities).length} bookable={bookable} />
              }

              {experience === undefined &&
                onDemand === undefined &&
                directBooking === undefined &&
                hotelStatus === undefined &&
                currentAvailabilities.availabilities === undefined && (
                  <Skeleton variant="rect" height="200" />
                )}
            </div>

            {reviewCount > 0 && (
              <div className={classes.reviewBlock}>
                <div
                  className={
                    classes.horizontalEelements + " " + classes.container
                  }
                >
                  <div>
                    <h2
                      className={clsx(
                        classes.secondaryTitle,
                        classes.sameLineText
                      )}
                    >
                      <Emoji symbol="⭐" /> {`${averageReview} / 5`}
                    </h2>
                    <p className={classes.sameLineText}>
                      {" "}
                      {I18n.t("review", { count: reviewCount })}
                    </p>
                  </div>
                </div>
                <div className={classes.container}>
                  <ReviewSlide
                    reviews={reviews.slice(0, 5)}
                    truncate={true}
                    listCols={2.3}
                  />
                </div>
                <br />
                <br />
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={classes.nav}>
        <MediaContextProvider>
          <Media at="sm">{ProductMobile(1.3)}</Media>
          <Media at="md">{ProductMobile(1.3)}</Media>
          <Media greaterThanOrEqual="lg">{ProductMobile(1.3)}</Media>
        </MediaContextProvider>
      </div>
    </>
  );
}

export default ProductModal;
