import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import UserRegister from "./UserRegister";
import UserLogin from "./UserLogin";
import { useSelector, useDispatch } from "react-redux";
import { setDesktopUserLoginFormStatus } from "../../../../redux/actions/actions";
import { DialogActions } from "@material-ui/core";
import I18n from "../../../../../i18n-js/index.js.erb";
import { Google } from "../../../../../helpers/app/svg";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "reactstrap";
import ClearIcon from "@material-ui/icons/Clear";
import clsx from "clsx";

function UserLoginWrap(props) {
  const dispatch = useDispatch();

  const desktopUserLoginFormStatus = useSelector(
    (state) => state.desktopUserLoginFormStatus
  );

  const currentLocale = useSelector(
    (state) => state.currentLocale
  );

  const handleUserLoginClose = () => {
    dispatch(
      setDesktopUserLoginFormStatus({
        open: false,
        register: false,
        signin: false,
        selectRegister: false,
        selectSignin: false,
        notClosable: desktopUserLoginFormStatus.notClosable ? true : false,
      })
    );
  };

  const handleMode = (mode, source) => {
    dispatch(
      setDesktopUserLoginFormStatus({
        open: true,
        register: mode === "register" ? true : false,
        signin: mode === "signin" ? true : false,
        selectRegister: mode === "selectRegister" ? true : false,
        selectSignin: mode === "selectSignin" ? true : false,
        source: source,
        notClosable: desktopUserLoginFormStatus.notClosable ? true : false,
      })
    );
  };

  const useStyles = makeStyles((theme) => ({
    loginBtn: {
      border: "1px solid #2d2d2d",
      borderRadius: "5px",
      padding: "5px 10px !important",
      marginTop: "10px",
      color: "#2d2d2d !important",
      fontWeight: "500",
      minHeight: "35px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      textAlign: "center",
      height: "48px",
      backgroundColor: "white",
      "&:hover": {
        textDecoration: "none",
        boxShadow: "0 0 4px  rgba(0,0,0,0.45)",
      },
    },
    loginText: {
      textAlign: "center",
      display: "inline-block",
      width: "100%",
      textDecoration: "none",
    },
    loginIcon: {
      maxWidth: "20px",
      float: "left",
      width: "20px",
      fontSize: "17px",
      display: "block",
      textDecoration: "none",
    },
    facebookLogo: {
      color: "#3b5998",
    },
    actions: {
      justifyContent: "center !important",
    },
    horizontalEelements: {
      display: "flex",
      justifyContent: "space-between",
    },
    selectMode: {
      textDecoration: "underline",
      cursor: 'pointer'
    }
  }));

  const classes = useStyles();

  return (
    <React.Fragment>
      <div>
        <Dialog
          open={desktopUserLoginFormStatus.open}
          onClose={
            desktopUserLoginFormStatus.notClosable
              ? false
              : handleUserLoginClose
          }
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          scroll={"body"}
          fullWidth={true}
          maxWidth={"xs"}
          fullScreen={props.fullscreen}
        >
          {(desktopUserLoginFormStatus.selectRegister ||
            desktopUserLoginFormStatus.selectSignin) && (
              <>
                <DialogTitle>
                  <div className={classes.horizontalEelements}>
                    {props.reason
                      ? I18n.t("do_for", {
                        action: I18n.t(
                          desktopUserLoginFormStatus.selectRegister
                            ? "sign_up"
                            : "login"
                        ),
                        reason: props.reason,
                      })
                      : I18n.t(
                        desktopUserLoginFormStatus.selectRegister
                          ? "sign_up"
                          : "login"
                      )}
                    <div>
                      {!desktopUserLoginFormStatus.notClosable && (
                        <ClearIcon
                          onClick={() => handleUserLoginClose()}
                          style={{ fontSize: 30 }}
                        />
                      )}
                    </div>
                  </div>
                </DialogTitle>
                <DialogContent>
                  {!props.widget && <a href="/auth/google_oauth2"
                                       onClick={(e) => {
                                        e.preventDefault();
                                        const state = btoa(JSON.stringify({ locale: currentLocale }));
                                        window.location.href = `/auth/google_oauth2?state=${encodeURIComponent(state)}`;
                                      }} 
                                      className={classes.loginBtn}>
                    {Google()}{" "}
                    <span className={classes.loginText}>
                      {I18n.t(
                        desktopUserLoginFormStatus.selectRegister
                          ? "register_with"
                          : "login_with",
                        { media: "Google" }
                      )}
                    </span>
                  </a>}

                  {/* <a href="/auth/facebook" className={classes.loginBtn}>
                  <span
                    className={
                      "fab fa-facebook " +
                      classes.facebookLogo +
                      " " +
                      classes.loginIcon
                    }
                  ></span>{" "}
                  <span className={classes.loginText}>
                    {I18n.t(
                      desktopUserLoginFormStatus.selectRegister
                        ? "register_with"
                        : "login_with",
                      { media: "Facebook" }
                    )}
                  </span>
                </a> */}

                  <button
                    className={classes.loginBtn}
                    onClick={() =>
                      handleMode(
                        desktopUserLoginFormStatus.selectRegister
                          ? "register"
                          : "signin",
                        desktopUserLoginFormStatus.source
                      )
                    }
                  >
                    <span
                      className={"fas fa-envelope " + classes.loginIcon}
                    ></span>
                    <span className={classes.loginText}>
                      {I18n.t(
                        desktopUserLoginFormStatus.selectRegister
                          ? "register_with"
                          : "login_with",
                        { media: "Email" }
                      )}
                    </span>
                  </button>
                </DialogContent>
              </>
            )}

          {desktopUserLoginFormStatus.register && (
            <>
              <DialogTitle>
                <div className={classes.horizontalEelements}>
                  {I18n.t("sign_up")}
                  <div>
                    {!desktopUserLoginFormStatus.notClosable && (
                      <ClearIcon
                        onClick={() => handleUserLoginClose()}
                        style={{ fontSize: 30 }}
                      />
                    )}
                  </div>
                </div>
              </DialogTitle>
              <DialogContent>
                <UserRegister subscription={props.subscription}></UserRegister>
              </DialogContent>
            </>
          )}
          {desktopUserLoginFormStatus.signin && (
            <>
              <DialogTitle>
                <div className={classes.horizontalEelements}>
                  {I18n.t("begin_session")}
                  <div>
                    {!desktopUserLoginFormStatus.notClosable && (
                      <ClearIcon
                        onClick={() => handleUserLoginClose()}
                        style={{ fontSize: 30 }}
                      />
                    )}
                  </div>
                </div>
              </DialogTitle>
              <DialogContent>
                <UserLogin subscription={props.subscription}></UserLogin>
              </DialogContent>
            </>
          )}

          <DialogActions className={classes.actions}>
            {(desktopUserLoginFormStatus.selectRegister ||
              desktopUserLoginFormStatus.register) && (
                <p
                  className={clsx("text-center", classes.selectMode)}
                  onClick={() =>
                    handleMode("selectSignin", desktopUserLoginFormStatus.source)
                  }
                >
                  {I18n.t("favorites__modalForm_accountAlready")}
                </p>
              )}
            {(desktopUserLoginFormStatus.selectSignin ||
              desktopUserLoginFormStatus.signin) && (
                <p
                  className={clsx("text-center", classes.selectMode)}
                  onClick={() =>
                    handleMode(
                      "selectRegister",
                      desktopUserLoginFormStatus.source
                    )
                  }
                >
                  {I18n.t("favorites__modalForm_noAccount")}
                </p>
              )}
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
}

export default UserLoginWrap;
