import React from "react";
import LazyImage from "../../utilities/lazyload";
import Rectangle from "images/Rectangle.png";
import { useState, useEffect } from "react";
import {
  capitalizeFirstLetter,
  timizeDistance,
  translate_products,
} from "../../../helpers/base";
import I18n from "../../../i18n-js/index.js.erb";
import { useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import ProductModal from "./ProductModal";
import WidgetProductModal from "../../widget/WidgetProductModal";
import { wallet_amount } from "../../../helpers/wallet/wallet";
import Emoji from "a11y-react-emoji";
import clsx from "clsx";
import { Button } from "@material-ui/core";
import tinycolor from "tinycolor2";

let noMarginTop = {
  marginTop: "0",
};

let prodNameStyles = {
  color: "grey",
  paddingTop: "15px",
};

let prodPriceStyles = {
  display: "block",
  margin: "0 10px 0 0",
  color: "rgb(45,45,45)",
};

let notAvailableStyles = {
  display: "block",
  margin: "0 10px 0 0",
  fontWeight: "800",
};

let listingNameStyles = {
  fontWeight: "600 !important",
  color: "rgb(45,45,45)",
  fontSize: "14px",
};

let cityAreaStyles = {
  fontSize: "14px",
};

let lazyImgStyles = {
  height: "20rem",
  width: "100%",
  borderRadius: "10px",
  objectFit: "cover",
};

let prodPriceDiscount = {
  textDecoration: 'none !important',
  fontWeight: 800,
  padding: 2,
  background: "#F7EB67",
  color: 'black',
}

function ProductBlockFull(props) {
  const currentLocale = useSelector((state) => state.currentLocale);
  const selectedArea = capitalizeFirstLetter(
    useSelector((state) => state.area)
  );
  const [openExperience, setOpenExperience] = useState(false);
  const [innerScroll, setInnerScroll] = useState("paper");
  I18n.locale = currentLocale;

  const discount = Math.round(((props.week_price-props.base_week_price)/props.base_week_price)*100)

  const URL = props.widget
    ? `/${currentLocale}/${props.area}/hotels/${props.roomSlug}`
    : `/${currentLocale}/${props.area}/hotels/${props.roomSlug}/${props.slug}`;

  const handleExperienceOpen = () => {
    setOpenExperience(true);
    analytics.track("Product Viewed", {
      product_id: props.id,
      name: props.name,
      brand: props.listing_name,
      price: props.week_price,
      category: props.category,
      url: "https://www.hotelbreak.com/" + URL,
      image_url: props.block_photo,
      currency: props.currency,
    });
  };

  const handleExperienceClose = () => {
    setOpenExperience(false);
  };

  useEffect(() => {
    props.forceOpenId === props.id ?
      setOpenExperience(true)
    : null
  }, [props.forceOpenId])

  const noClick = (e) => {
    e.preventDefault();
  };

  var walletAmount = wallet_amount(
    1,
    props.week_price * 100,
    0,
    1,
    props.max_guest
  );

  const useStyles = makeStyles((theme) => ({
    horizontalElements: {
      display: "flex",
      justifyContent: "space-between",
    },
    paddingTop: {
      paddingTop: "2rem",
      cursor: "pointer",
    },
    dialogTitle: {
      fontSize: "2rem",
      fontWeight: "300",
    },
    dialogContent: {
      padding: "0 !important",
    },
    wallet: {
      background: "white",
      borderRadius: "10px",
    },
    fullBtn: {
      width: "100%",
    },
    headings: {
      fontFamily:
        props.brand && props.brand?.font ? props.brand?.font : "inherit",
    },
    primaryColor: {
      backgroundColor: props.brand?.name !== 'widget' ? `${props.brand?.main_color} !important` : "#F7EB67",
      color: (props) => props.brand && tinycolor(props.brand?.main_color).isDark() ? '#FFF' : '#000 !important',
      "& *": {
        color: (props) => props.brand && tinycolor(props.brand?.main_color).isDark() ? '#FFF' : '#000 !important',
      },
      border: props.brand?.name !== 'widget' ?
                tinycolor(props.brand?.main_color).isLight() ?
                  "1px solid black"
                :
                  `1px solid ${props.brand?.main_color} !important`
              :
                "1px solid #F7EB67",
      borderRadius: '2px',
      fontWeight: 'bold',
      padding: '10px 25px 10px 25px',
      "&.Mui-disabled": {
        filter: "opacity(0.5)"
      },
      "&:hover": {
        backgroundColor: props.brand?.name !== 'widget' ? `${props.brand?.main_color} !important` : "#0098d7",
        border: props.brand?.name !== 'widget' ?
                tinycolor(props.brand?.main_color).isLight() ?
                  "1px solid black"
                :
                  `1px solid ${props.brand?.main_color} !important`
              :
                "1px solid #F7EB67",
      }
    },
  }));

  const classes = useStyles();

  const newButtonStyles = props.brand ? {
    backgroundColor: props.brand.main_color,
    color: props.brand.secondary_color,
    fontFamily: props.brand.font,
    bottom: '10px',
    top: 'unset',
    borderRadius: '20px',
    fontSize: '10px',
    lineHeight: '20px',
    fontWeight: 1000
  } : {};

  const ExperienceDialog = withStyles({
    root: {
      marginTop: "20px !important",
    },
    paper: {
      margin: "7rem auto",
      width: "100%",
    },
    paperScrollPaper: {
      maxHeight: "100%",
    },
  })(Dialog);

  const selectAdults = (adults) => {
    if (adults && adults !== false && adults !== 0) {
      return "&adults=" + adults;
    } else {
      return "";
    }
  };

  const selectKids = (kids) => {
    if (kids && kids !== false && kids !== 0) {
      return "&kids=" + kids;
    } else {
      return "";
    }
  };

  return (
    <React.Fragment>
      <a
        href={
          !props.brand || props.widgetFeatured
            ? `${URL}?${
                props.selectedDate &&
                props.selectedDate !== null &&
                props.selectedDate !== "undefined"
                  ? "available_date=" + props.selectedDate
                  : ""
              }${props.selectedPax && selectAdults(props.selectedPax.adults)}${
                props.selectedPax && selectKids(props.selectedPax.kids)
              }`
            :
              "#"
        }
        onClick={props.modal && noClick}
        className="toRoom marginbottom"
        alt={props.name}
        target="_blank"
        data-name={props.listing_name}
        data-price={props.week_price}
        data-position={props.index}
      >
        <div
          className="small-padding"
          id={props.id}
          onClick={props.modal ? handleExperienceOpen : undefined}
        >
          <div className="row result-hotel no-gutters" style={noMarginTop}>
            <div className="col-md-12">
              {props.block_photo && (
                <LazyImage
                  src={`https://www.hotelbreak.com${props.block_photo}`}
                  lqip={Rectangle}
                  alt={props.name}
                  lazyImgStyles={lazyImgStyles}
                >
                  {" "}
                </LazyImage>
              )}
              {props.is_new && <span className={"category new"} style={newButtonStyles} >NEW</span>}
              {props.has_calendar && <span className="category direct_booking" style={newButtonStyles}>🕟 &nbsp; {I18n.t("select_time")}</span>}
            </div>

            <div className={clsx("col-md-12", props.brand && classes.headings)} style={prodNameStyles}>
              <div className="col-md-12 col-xs-12 no-padding">
                <span
                  className={clsx(
                    "product_name",
                    props.brand && classes.headings
                  )}
                >
                  {props.name.length > 60
                    ? props.name.slice(0, 60) + "..."
                    : props.name}
                </span>
              </div>

              {props.widget && (
                <div className="col-md-12 col-xs-12 no-padding">
                  <span style={listingNameStyles}>
                    {props.categories &&
                      translate_products(props.categories, currentLocale).join(
                        " • "
                      )}
                  </span>
                </div>
              )}

              <div className="col-md-12 col-xs-12 no-padding product_cat">
                <span style={listingNameStyles}>
                  {props.listing_name}&nbsp;{" "}
                  <small>
                    {props.star_rating && "*".repeat(props.star_rating)}
                  </small>
                </span>
                <br></br>
                {props.city && (
                  <span style={cityAreaStyles}>
                    {capitalizeFirstLetter(props.city)}
                    {props.province_name ? `, ${props.province_name}` : " "}
                    {props.timeDistance &&
                    props.timeDistance != 0 &&
                    selectedArea ? (
                      <span>
                        {timizeDistance(
                          props.timeDistance,
                          selectedArea,
                          props.city
                        )}
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                )}
              </div>

              <div className="col-md-12 col-xs-12 no-padding" style={{display: 'flex'}}>
                {typeof props.week_price !== "undefined" &&
                  props.week_price !== 0 && (
                    <p style={prodPriceStyles}>
                      {props.price_model === "net" &&
                        props.max_guest > 1 &&
                        I18n.t("price_from_per_x", {
                          price: props.week_price,
                          currency: props.currency,
                          pers: props.max_guest,
                        })}

                      {props.price_model === "net" &&
                        props.max_guest <= 1 &&
                        I18n.t("price_from_per_pers", {
                          price: props.week_price,
                          currency: props.currency,
                        })}

                      {props.price_model !== "net" &&
                        props.max_guest > 1 &&
                        I18n.t("price_from_per_x", {
                          price: props.week_price,
                          currency: props.currency,
                          pers: props.max_guest,
                        })}

                      {props.price_model !== "net" &&
                        props.max_guest <= 1 &&
                        I18n.t("price_from_per_pers", {
                          price: props.week_price,
                          currency: props.currency,
                        })}
                    </p>
                  )}
                  {discount < -5 && (
                    <div style={{flex: 1}}>
                      <span style={{textDecoration: 'line-through'}} >{props.base_week_price}{props.currency}</span>{" "}
                      <span style={prodPriceDiscount}>{discount}%</span>
                    </div>
                  )}

                {!props.week_price && (
                  <p style={notAvailableStyles}>{I18n.t("not_available")}</p>
                )}
              </div>
            </div>
            {props.widget &&
              <Button className={clsx("btn", classes.primaryColor, classes.fullBtn)} style={{position: "absolute", bottom: 0, left: 0}}>{I18n.t("see_experience")}</Button>
            }
          </div>
        </div>
        <ExperienceDialog
          open={openExperience}
          onClose={handleExperienceClose}
          className={classes.paper}
          scroll={innerScroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <div className={classes.horizontalElements}>
              <p className={classes.dialogTitle}>{props.name}</p>
              <div>
                <ClearIcon
                  onClick={() => handleExperienceClose()}
                  style={{ fontSize: 30 }}
                ></ClearIcon>
              </div>
            </div>
          </DialogTitle>
          <DialogContent
            dividers={innerScroll === "paper"}
            className={classes.dialogContent}
          >
            {props.brand ? (
              <WidgetProductModal
                product={props.slug}
                hotel={props.roomSlug}
                locale={currentLocale}
                area={props.area}
                modal={props.modal}
                brand={props.brand}
              />
            ) : (
              <ProductModal
                product={props.slug}
                hotel={props.roomSlug}
                locale={currentLocale}
                area={props.area}
                modal={props.modal}
              />
            )}
          </DialogContent>
        </ExperienceDialog>
      </a>
    </React.Fragment>
  );
}

export default ProductBlockFull;
