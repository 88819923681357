import React from "react";
import { useState, useEffect } from "react";
import I18n from "../../../../i18n-js/index.js.erb";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { setSelectedTimeFrame } from "../../../redux/actions/actions";
import { useTimeFrameSelectorStyles } from "../styles/TimeFrameSelectorStyles";
import HotelBreakLoader from "../../components/loaders/hotelBreakLoader";
import clsx from "clsx";

function TimeFrameSelector(props) {
  const dispatch = useDispatch();
  const selectedTimeFrame = useSelector((state) => state.selectedTimeFrame);
  const classes = useTimeFrameSelectorStyles();
  const [timeFrames, setTimeFrames] = useState(false);
  const [loadingCalendar, setLoadingCalendar] = useState(false);

  const handleSelectTimeFrame = (start_hour, finish_hour) => {
    dispatch(
      setSelectedTimeFrame({
        ...selectedTimeFrame,
        status: true,
        start_hour: start_hour,
        finish_hour: finish_hour,
      })
    );
  };

  const styleTimeFrameSelector = (start_hour, finish_hour) => {
    return (
      <div
        className={classes.timeFrameRow}
        key={`${start_hour}-${finish_hour}`}
        onClick={() => handleSelectTimeFrame(start_hour, finish_hour)}
      >
        <div className={classes.timeText}>
          {start_hour} - {finish_hour}{" "}
        </div>
        <div
          id={`timeframe-${start_hour} - ${finish_hour}`}
          name={`timeframe-${start_hour} - ${finish_hour}`}
          className={clsx(
            classes.radioButton,
            selectedTimeFrame.start_hour === start_hour &&
              selectedTimeFrame.finish_hour === finish_hour &&
              classes.selectedTime
          )}
          onClick={() => handleSelectTimeFrame(start_hour, finish_hour)}
        ></div>
      </div>
    );
  };

  useEffect(() => {
    let mounted = true;
    if (selectedTimeFrame.open) {
      setLoadingCalendar(true)
      axios
        .get("/api/main/reservations/calculate_hours", {
          params: {
            start_date: props.selectedDate,
            adults: props.adults,
            kids: props.kids,
            hotel_slug: props.hotelSlug,
            product_slug: props.productSlug,
          },
        })
        .then((response) => {
          if (response.status === 200 && mounted) {
            setTimeFrames(response.data.hours);
          }
          setLoadingCalendar(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    return () => {
      mounted = false;
    };
  }, [props.selectedDate]);

  return (
    <>
      {loadingCalendar && <HotelBreakLoader />}
      {!loadingCalendar && (
        <React.Fragment>
          <p className={classes.timeFramesTitle}>
            {props.massage ? I18n.t("timeframes_title") : I18n.t("timeframes_title_spa")}
          </p>
          <div className={classes.timeFramesWrap}>
            <form>
              {timeFrames &&
               timeFrames.map((timeframe) => (
                 styleTimeFrameSelector(timeframe[0], timeframe[1])
               ))}
            </form>
          </div>
        </React.Fragment>
      )}
    </>
  );
}

export default TimeFrameSelector;
