import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createMedia } from "@artsy/fresnel";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import SelectArea from "../main/components/dialogs/SelectArea";
import { makeStyles } from "@material-ui/core/styles";
import WidgetNav from "./WidgetNav";
import Skeleton from "@material-ui/lab/Skeleton";
import AreaBlock from "../main/components/areas/AreaBlock";
import AreaBlocklarge from "../main/components/areas/AreaBlocklarge";
import SkeletonAreablock from "../main/components/areas/SkeletonAreablock";
import SkeletonAreablocklarge from "../main/components/areas/SkeletonAreablocklarge";
import I18n from "../../i18n-js/index.js.erb";
import clsx from "clsx";
import WidgetFooter from "./WidgetFooter";
import WidgetSlideProductBlock from "./WidgetSlideProductBlock";

function WidgetLandingPage(props) {
  const csrfToken = useSelector((state) => state.csrfToken);
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  const currentLocale = useSelector((state) => state.currentLocale);

  const [brand, setBrand] = useState(false);

  const [open, setOpen] = useState(false);
  const [ready, setReady] = useState(false);
  const [featuredArray, setFeaturedArray] = useState(false);
  const [areasArray, setAreasArray] = useState(false);
  const [roomsArray, setRoomsArray] = useState(false);
  const [subBrandsArray, setSubBrandsArray] = useState(false);
  const [categoriesArray, setCategoriesArray] = useState(false);
  const [section, setSection] = useState("")

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSection = (section) => {
    setSection(section)
  }

  const StyledDialog = withStyles({
    paper: {
      width: "120rem",
      height: "50rem",
    },
  })(Dialog);

  const getBrand = () => {
    props.brand &&
      axios.get(`/widget/brands/${props.brand.slug}?locale=${currentLocale}`)
      .then((response) => {
        setBrand(response.data.data.attributes)
      });
  }

  const getBrandDetail = () => {
    props.brand &&
      axios.get(`/widget/brands/${props.brand.slug}/room_list?locale=${currentLocale}`)
      .then((response) => {
        setSubBrandsArray(response.data.sub_brands)
        setAreasArray(response.data.provinces)
        setCategoriesArray(response.data.categories)
        setRoomsArray(response.data.rooms)
        setFeaturedArray(response.data.featured)
        if (response.data.provinces.length > 1) {
          setSection("area")
          setRoomsArray(false)
        } else if (response.data.rooms.length > 1 && props.brand.name !== 'Lesbonsplansoff') {
          setSection("area")
        } else if (response.data.sub_brands.length > 1) {
          setSection("subbrand")
        } else {
          setSection("experience")
        }
      })
  }

  useEffect(() => {
    setReady(true);
    getBrand();
    getBrandDetail();
  }, [props.brand])

  useEffect(() => {
    const WebFont = require('webfontloader')
    brand?.font &&
    WebFont.load({
      google: {
        families: [brand?.font, 'sans-serif']
      }
    })
  }, [brand?.font])

  const useStyles = makeStyles((theme) => ({
    root: {},
    headings: {
      fontFamily:  brand?.font ? brand?.font : 'inherit',
    },
    hero: {
      position: "relative",
      width: "100%",
      padding: "0 0 2rem",
      backgroundImage: brand?.background ? `url(${brand.background})` : "undefined",
      backgroundSize: "cover",
      backgroundPosition: "50%",
      backgroundRepeat: "no-repeat",
      height: '40vh'
    },
    heroLarge: {
      position: "relative",
      width: "100%",
      padding: "0 0 2rem",
      backgroundImage: brand?.background ? `url(${brand.background})` : "undefined",
      backgroundSize: "cover",
      backgroundPosition: "70%",
      backgroundRepeat: "no-repeat",
    },
    heroBlock: {
      padding: "15px 30px",
      marginLeft: '40vw',
      width: '30vw',
      background: 'white',
      borderRadius: '10px',
      marginTop: '20vh',
      marginBottom: '10vh'
    },
    heroBlockSmall: {
      width: '100vw',
      background: 'white',
      borderRadius: '10px',
      marginBottom: '10vh',
      padding: '15px 30px',
      position: 'relative'
    },
    heroText: {
      fontSize: "24px",
      fontWeight: "400 !important",
    },
    heroTextLarge: {
      fontSize: "30px",
      fontWeight: "400 !important",
    },
    linkText: {
      fontWeight: "800 !important",
      fontSize: "14px",
      marginBottom: "2rem",
      borderRadius: "10px",
      width: '100%'
    },
    secondaryHeroText: {
      fontFamily: "Open Sans",
      fontStyle: "italic",
      fontWeight: "600",
      fontSize: "18px",
    },
    verticalDivider: {
      display: "block",
      height: "2vh",
      width: "0",
      margin: "1rem auto 0",
      border: "1px solid black",
    },
    provinceBlock: {
      //padding: "0 20px 10px",
    },
    provinceList: {
      display: "flex",
      flexWrap: "wrap",
      listStyle: "none",
      margin: "3rem -.5rem 0",
      padding: "0",
    },
    province: {
      marginBottom: "10px",
      position: "relative",
      width: "100%",
      paddingRight: ".5rem",
      paddingLeft: ".5rem",
      flex: "0 0 50%",
      maxWidth: "50%",
    },
    category: {
      marginBottom: "10px",
      position: "relative",
      width: "100%",
      paddingRight: ".5rem",
      paddingLeft: ".5rem",
      flex: "0 0 100%",
      maxWidth: "100%",
    },
    categoryLarge: {
      marginBottom: "10px",
      position: "relative",
      width: "100%",
      paddingRight: "2rem",
      paddingLeft: "2rem",
      paddingBottom: "4rem",
      flex: "0 0 50%",
      maxWidth: "50%",
      cursor: "pointer",
    },
    provinceName: {
      position: "absolute",
      zIndex: 1,
      display: "flex",
      top: 0,
      height: "100%",
      width: "80% !important",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      color: "#fff",
      fontWeight: 600,
      lineHeight: 1.2,
      fontSize: "2rem",
      fontFamily: brand ? brand?.font : 'inherit',
    },
    provinceNameLarge: {
      position: "absolute",
      zIndex: 1,
      display: "flex",
      top: 0,
      height: "100%",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      color: "#fff",
      fontWeight: 600,
      lineHeight: 1.2,
      fontSize: "3rem",
      paddingBlockEnd: "4rem",
      paddingRight: "4rem",
    },
    provinceImage: {
      width: "100%",
      height: "17vh",
      minHeight: "17rem",
      borderRadius: "10px",
      backgroundPosition: "50%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
    provinceImageLarge: {
      width: "100%",
      height: "40vh",
      minHeight: "35rem",
      borderRadius: "10px",
      backgroundPosition: "50%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
    experienceBlock: {
      padding: "0 20px",
      position: "relative",
      marginTop: "20px",
    },
    sectionTitleList: {
      marginTop: '10px'
    },
    sectionTitle: {
      paddingLeft: '1.5rem',
      display: 'inline-block',
      marginRight: '25px',
      fontSize: '24px',
      color: 'lightgrey',
      fontWeight: 200,
      cursor: 'pointer'
    },
    sectionTitleMobile: {
      display: 'inline-block',
      fontSize: '16px',
      color: 'darkgrey',
      fontWeight: '200 !important',
      cursor: 'pointer',
      marginRight: '10px',
      padding: '6px 10px',
      "&$sectionActive": {
        textDecoration: 'underline !important',
        color: 'black'
      },
      "&:first-child": {
        marginLeft: '15px'
      }
    },
    sectionActive: {
      color: 'black',
      textDecoration: 'underline'
    },
    sectionSubTitle: {
      paddingLeft: '1.5rem',
    },
    giftCardBlock: {
      padding: '0 4rem'
    },
    widgetContainer: {
      width: '90% !important',
      margin: '0 5% !important'
    }
  }));

  const onAreaSelect = (area) => {
    document.location = area.url
  }

  const classes = useStyles();

  const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      sm: 0,
      md: 768,
      lg: 1024,
      xl: 1192,
    },
  });

  const landing = (blocks) => {
    return (
      <React.Fragment>
        <div className={classes.root} style={{ visibility: ready ? 'visible' : 'hidden' }}>
            <div className={classes.heroLarge}>
              <WidgetNav brand={brand} location={"hotel"} />
              <div className={"container"}>
                {blocks > 1 ?
                  brand?.description && brand?.description != '' &&
                    <div className={classes.heroBlock} >
                      <div dangerouslySetInnerHTML={{__html: brand.description}} />
                    </div>
                :
                  <div style={{height: '300px'}} />
                }
              </div>
            </div>
          </div>
          {blocks == 1 &&
            brand?.description && brand?.description != '' &&
              <div style={{width: '80%', margin: '20px auto 0'}}>
                <div dangerouslySetInnerHTML={{__html: brand.description}} />
              </div>
          }
          {brand?.partner_type == 'brand' && featuredArray &&
            <div className={classes.root}>
              <div className={classes.widgetContainer}>
                <WidgetSlideProductBlock
                  key={'featured'}
                  selector={'featured'}
                  products={featuredArray}
                  title={I18n.t("guides_featured_subtitle")}
                  emoji={""}
                  listCols={blocks > 1 ? blocks + 1.3 : 1.3}
                  locale={'es'}
                  brand={brand}
                  widgetFeatured={true}
                />
              </div>
            </div>
          }
          <div className={classes.root}>
            <div className={classes.widgetContainer}>
              <div className={classes.provinceBlock}>
                <div className={classes.sectionTitleList} >
                  {areasArray || subBrandsArray || categoriesArray ?
                    (<React.Fragment>
                      {areasArray && areasArray.length > 1 ?
                        (
                          <h2
                            className={clsx(
                              blocks > 1 ? classes.sectionTitle : classes.sectionTitleMobile,
                              section == 'area' && classes.sectionActive, classes.headings)}
                            onClick={() => handleSection('area')}
                          >
                            {I18n.t("widget_by_place")}
                          </h2>
                        ) : roomsArray && roomsArray.length > 1 && brand.name !== 'Lesbonsplansoff' &&
                          (<h2
                            className={clsx(
                              blocks > 1 ? classes.sectionTitle : classes.sectionTitleMobile,
                              section == 'area' && classes.sectionActive, classes.headings)}
                            onClick={() => handleSection('area')}
                          >
                            {I18n.t("widget_by_hotel")}
                          </h2>)
                      }
                      {subBrandsArray && subBrandsArray.length > 1 &&
                        <h2
                          className={clsx(
                          blocks > 1 ? classes.sectionTitle : classes.sectionTitleMobile,
                          section == 'subbrand' && classes.sectionActive, classes.headings)}
                          onClick={() => handleSection('subbrand')}
                        >
                          {I18n.t("widget_by_brand")}
                        </h2>
                      }
                      {categoriesArray && categoriesArray.length > 1 &&
                        <h2
                          className={clsx(
                          blocks > 1 ? classes.sectionTitle : classes.sectionTitleMobile,
                          section == 'experience' && classes.sectionActive, classes.headings)}
                          onClick={() => handleSection('experience')}
                        >
                          {I18n.t("widget_by_category")}
                        </h2>
                      }
                    </React.Fragment>
                    )
                  :
                    (
                      <React.Fragment>
                        <h2>
                          <Skeleton className={clsx(
                            blocks > 1 ? classes.sectionTitle : classes.sectionTitleMobile,
                            section == 'area' && classes.sectionActive, classes.headings)}
                            width={120}
                          />
                          <Skeleton className={clsx(
                            blocks > 1 ? classes.sectionTitle : classes.sectionTitleMobile,
                            section == 'subbrand' && classes.sectionActive, classes.headings)}
                            width={100}
                          />
                          <Skeleton className={clsx(
                            blocks > 1 ? classes.sectionTitle : classes.sectionTitleMobile,
                            section == 'experience' && classes.sectionActive, classes.headings)}
                            width={110}
                          />
                        </h2>
                      </React.Fragment>
                    )
                  }
                </div>
                {section && section === 'area' && (
                  areasArray && areasArray.length > 1 ? (
                    <React.Fragment>
                      <ul className={classes.provinceList}>
                        {areasArray.sort((a, b) => a.name > b.name ? 1 : -1).map((area) => (
                              <AreaBlocklarge
                                area={area}
                                font={brand?.font}
                                blocks={blocks}
                                onAreaSelect={onAreaSelect}
                              />
                            ))
                          }
                      </ul>
                    </React.Fragment>
                ) : roomsArray && roomsArray.length > 1 && brand.name !== 'Lesbonsplansoff' &&
                  (<React.Fragment>
                    <ul className={classes.provinceList}>
                      {roomsArray.sort((a, b) => a.name > b.name ? 1 : -1).map((room) => (
                            <AreaBlocklarge
                              area={room}
                              font={brand?.font}
                              blocks={blocks}
                              onAreaSelect={onAreaSelect}
                            />
                          ))
                        }
                    </ul>
                  </React.Fragment>)
                )
                }
                {section && section === 'subbrand' &&
                subBrandsArray && subBrandsArray.length > 1 ?
                  <React.Fragment>
                    <ul className={classes.provinceList}>
                      {subBrandsArray.sort((a, b) => a.name > b.name ? 1 : -1).map((subBrand) => (
                            <AreaBlocklarge
                              area={subBrand}
                              blocks={blocks}
                              font={brand?.font}
                              onAreaSelect={onAreaSelect}
                            />
                          ))
                      }
                    </ul>
                  </React.Fragment>
                  : null
                }
                {section && section === 'experience' &&
                categoriesArray && categoriesArray.length > 1 ?
                  <React.Fragment>
                    <ul className={classes.provinceList}>
                      {categoriesArray.map((category) => (
                            <AreaBlocklarge
                              area={category}
                              font={brand?.font}
                              blocks={blocks}
                              onAreaSelect={onAreaSelect}
                            />
                          ))
                      }
                    </ul>
                  </React.Fragment>
                  : null
                }
              </div>
            </div>
          </div>
          <WidgetFooter
            brand={brand}
          />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <MediaContextProvider>
        <Media at="sm">
          <div className={classes.root} style={{ visibility: ready ? 'visible' : 'hidden' }}>
            {landing(1)}
          </div>
        </Media>
        <Media at="md">
          <div className={classes.root} style={{ visibility: ready ? 'visible' : 'hidden' }}>
            {landing(2)}
          </div>
        </Media>
        <Media greaterThanOrEqual="lg">
          <div className={classes.root} style={{ visibility: ready ? 'visible' : 'hidden' }}>
            {landing(3)}
          </div>
        </Media>
      </MediaContextProvider>
    </React.Fragment>
  );
}

export default WidgetLandingPage;
