import React from "react";
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import Emoji from "a11y-react-emoji";
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import I18n from '../../../i18n-js/index.js'
import { useSelector } from "react-redux";
import { to_eur } from "../../../helpers/base.js";

function UserNav(props) {

  const useStyles = makeStyles((theme) => ({
    buttonText: {
      color: 'black',
      fontSize: '16px'
    },
    emojis: {
      marginRight: '15px'
    },
    userNav: {
      width: '390px'
    }
  }));

  const classes = useStyles();

  const currentLocale = useSelector((state) => state.currentLocale);
  const currentUser = useSelector((state) => state.currentUser);

  return (
    <div className={classes.userNav}>
      <DialogContent>
        <a
          href={`/${currentLocale}/wallet`}
          title="Tu wallet"
        >
          <ListItem button className={classes.buttonText}>
            <Emoji symbol="👛" className={classes.emojis} /> <p>{I18n.t('my_wallet')} <small>({to_eur(currentUser.wallet === null ? 0 : currentUser.wallet.balance)}€)</small></p>
          </ListItem>
        </a>
        <Divider />
        <a
          href={`/${currentLocale}/users/${currentUser.id}`}
          title="Tus reservas"
        >
          <ListItem button className={classes.buttonText}>
            <Emoji symbol="🎟️" className={classes.emojis} /> <p>{I18n.t('my_passes')}</p>
          </ListItem>
        </a>
        <Divider />
        <a
          href={`/${currentLocale}/favorites/${currentUser.id}`}
          title="Tu wishlist"
        >
          <ListItem button className={classes.buttonText}>
            <Emoji symbol="❤️" className={classes.emojis} /> <p>{I18n.t('my_favorites')}</p>
          </ListItem>
        </a>
        <Divider />
        <a
          href={`/${currentLocale}/profile`}
          title="Tu perfil"
        >
          <ListItem button className={classes.buttonText}>
            <Emoji symbol="👤" className={classes.emojis} /> <p>{I18n.t('my_info')}</p>
          </ListItem>
        </a>
        <Divider />
      </DialogContent>
    </div>
  )
}

export default UserNav;
