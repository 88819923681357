import React from "react";
import { useStylesPriceSelection } from "../../styles/stepStyles";
import clsx from "clsx";
import { Input, Label } from "reactstrap";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "@mui/material/Button";
import {
  setAreaHomeProducts,
  setStepsVoucherCreationParams,
  setAreaHomeHotelCategories,
} from "../../../../redux/actions/actions";
import axios from "axios";
import { convertDate, to_eur } from "../../../../../helpers/base";
import Emoji from "a11y-react-emoji";
import I18n from "../../../../../i18n-js/index.js.erb";
import WidgetLoader from "../../../WidgetLoader";
import { SET_CURRENT_USER } from "../../../../redux/actions/constants";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";

function ProductForm(props) {
  const schema = yup
    .object({
      voucherPrice: yup
        .number()
        .required(I18n.t("is_required"))
        .positive(I18n.t("validation_positive_number")),
    })
    .required();

  const {
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();
  const classes = useStylesPriceSelection();

  const searchParams = new URLSearchParams(window.location.search);

  const DefaultAdults = parseInt(searchParams.get("adults")) || 1;
  const DefaultKids = parseInt(searchParams.get("kids")) || 0;

  const stepsVoucherCreationParams = useSelector(
    (state) => state.stepsVoucherCreationParams
  );

  const [product, setProduct] = useState(false);
  const [selectedPax, setSelectedPax] = useState(
    stepsVoucherCreationParams.step1.adults
      ? stepsVoucherCreationParams.step1.adults
      : DefaultAdults + DefaultKids >
        stepsVoucherCreationParams.step1.productInfo?.ticket_number
      ? 1
      : DefaultAdults
  );
  const [selectedPaxKids, setSelectedPaxKids] = useState(
    stepsVoucherCreationParams.step1.kids
      ? stepsVoucherCreationParams.step1.kids
      : DefaultAdults + DefaultKids >
        stepsVoucherCreationParams.step1.productInfo?.ticket_number
      ? 0
      : DefaultKids
  );
  const [wantToSelectMorePax, setWantToSelectMorePax] = useState(false);
  const [voucherPrice, setVoucherPrice] = useState();

  const isTotalPaxValid = (modification) => {
    return stepsVoucherCreationParams.step1.productInfo?.ticket_number
      ? selectedPax + selectedPaxKids + modification <
          stepsVoucherCreationParams.step1.productInfo?.ticket_number *
            stepsVoucherCreationParams.step1.productInfo?.max_guest
      : false;
  };

  const csrfToken = useSelector((state) => state.csrfToken);
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;

  const handleRemovePax = () => {
    if (selectedPax > 1) {
      setSelectedPax(selectedPax - 1);

      if (isTotalPaxValid(-1)) {
        setWantToSelectMorePax(false);
      } else {
        //setWantToSelectMorePax(true);
      }
    }
  };

  const handleAddPax = () => {
    if (isTotalPaxValid(0)) {
      setSelectedPax(selectedPax + 1);
    } else {
      //setWantToSelectMorePax(true);
    }
  };

  const handleRemovePaxKids = () => {
    if (selectedPaxKids > 0) {
      setSelectedPaxKids(selectedPaxKids - 1);
      if (isTotalPaxValid(-1)) {
        setWantToSelectMorePax(false);
      } else {
        //setWantToSelectMorePax(true);
      }
    }
  };

  const handleAddPaxKids = () => {
    if (isTotalPaxValid(0)) {
      setSelectedPaxKids(selectedPaxKids + 1);
    } else {
      //setWantToSelectMorePax(true);
    }
  };

  const setPrice = (pvp, maxGuest) => {
    if (maxGuest === 1) {
      setVoucherPrice(to_eur(pvp) * 2);
      var price = to_eur(pvp) * 2;
      setValue("voucherPrice", price);
    } else {
      setVoucherPrice(to_eur(pvp));
      setValue("voucherPrice", to_eur(pvp));
    }
  };

  useEffect(() => {
    if (!stepsVoucherCreationParams.step1.productInfo?.status) {
      axios
        .get("/api/main/products/get_product.json", {
          params: { slug: props.product, lang: I18n.locale },
        })
        .then((response) => {
          dispatch(
            setStepsVoucherCreationParams({
              ...stepsVoucherCreationParams,
              step1: {
                ...stepsVoucherCreationParams.step1,
                productInfo: {
                  ...response.data.data.attributes,
                  status: true,
                  pvp: response.data.data.attributes.pvp,
                  ticket_number:
                    response.data.data.attributes.productTickets.ticket_number,
                  accept_kids:
                    response.data.data.attributes.productTickets.accept_kids,
                  max_guest:
                    response.data.data.attributes.productTickets.max_guest,
                },
              },
            })
          );

          setPrice(
            response.data.data.attributes.pvp,
            response.data.data.attributes.productTickets.max_guest
          );

          axios
            .post("/api/main/tracking/checkouts/started.json", {
              params: { amount: response.data.data.attributes.pvp },
            })
            .then((response) => {
              console.log(response.status);
            })
            .catch(function (error) {});
        })
        .catch(function (error) {});

      axios
        .post("/api/main/tracking/checkouts/viewed.json", {
          params: { step: 1, step_name: "price_selection" },
        })
        .then((response) => {
          console.log(response.status);
        })
        .catch(function (error) {});
    }
  }, []);

  const totalPrice = () => {
    return (
      parseFloat(
        to_eur(
          stepsVoucherCreationParams.step1.productInfo?.pvp *
            Math.ceil(
              selectedPax /
                stepsVoucherCreationParams.step1.productInfo?.max_guest
            )
        ).toFixed(2)
      ) +
      parseFloat(
        to_eur(
          stepsVoucherCreationParams.step1.productInfo?.pvpKids *
            Math.ceil(
              selectedPaxKids /
                stepsVoucherCreationParams.step1.productInfo?.max_guest
            )
        ).toFixed(2)
      )
    );
  };

  const onSubmit = () => {
    dispatch(
      setStepsVoucherCreationParams({
        ...stepsVoucherCreationParams,
        step1: {
          ...stepsVoucherCreationParams.step1,
          status: true,
          price: totalPrice(),
          adults: selectedPax,
          kids: selectedPaxKids,
          product_id: props.product,
        },
        step2: { status: true, print: false, evoucher: true },
      })
    );

    axios
      .post("/api/main/tracking/checkouts/completed.json", {
        params: { step: 1, step_name: "price_selection" },
      })
      .then((response) => {
        console.log(response.status);
      })
      .catch(function (error) {});

    props.handleNext();
  };

  return (
    <div>
      <section
        className={clsx(
          props.mobile ? classes.productWrapMobile : classes.productWrap
        )}
      >
        {stepsVoucherCreationParams.step1.productInfo?.status ? (
          <>
            <div
              className={clsx(
                props.mobile ? classes.productBoxMobile : classes.productBox
              )}
            >
              <strong>
                {stepsVoucherCreationParams.step1.productInfo?.name}
              </strong>
              <p>
                <Emoji symbol="🏨" />{" "}
                {stepsVoucherCreationParams.step1.productInfo?.hotel}
              </p>
              <p>
                <Emoji symbol="💶" />
                &nbsp;
                {stepsVoucherCreationParams.step1.productInfo?.max_guest === 1
                  ? I18n.t("price_from_per_pers", {
                      price: to_eur(
                        stepsVoucherCreationParams.step1.productInfo?.pvp
                      ),
                      currency: "€",
                    })
                  : I18n.t("price_from_per_x", {
                      price: to_eur(
                        stepsVoucherCreationParams.step1.productInfo?.pvp
                      ),
                      currency: "€",
                      pers: stepsVoucherCreationParams.step1.productInfo
                        .max_guest,
                    })}
              </p>
              {!props.mobile && (
                <div
                  className={clsx(props.mobile && classes.productDescMobile)}
                >
                  <small>Description</small>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        stepsVoucherCreationParams.step1.productInfo
                          .description,
                    }}
                  />
                </div>
              )}
            </div>
            <div
              className={clsx(
                props.mobile ? classes.productBoxMobile : classes.productBox
              )}
            >
              <img
                className={classes.productImage}
                src={`https://www.hotelbreak.com/${stepsVoucherCreationParams.step1.productInfo?.blockPhoto}`}
              />
            </div>
            {props.mobile && (
              <div className={clsx(props.mobile && classes.productDescMobile)}>
                <small>Description</small>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      stepsVoucherCreationParams.step1.productInfo?.description,
                  }}
                />
              </div>
            )}
          </>
        ) : (
          <WidgetLoader brand={props.brand} />
        )}
      </section>
      {stepsVoucherCreationParams.step1.productInfo?.status && (
        <form>
          {stepsVoucherCreationParams.step1.productInfo?.status && (
            <React.Fragment>
              <p>
                {I18n.t("adults")}
                {stepsVoucherCreationParams.step1.productInfo?.max_guest ===
                  1 &&
                  " (" +
                    to_eur(
                      stepsVoucherCreationParams.step1.productInfo?.pvp
                    ).toFixed(2) +
                    "€)"}
              </p>
              <div className="row">
                <div className="col-xs-4 text-center">
                  <Fab
                    className={clsx(classes.primaryColor, classes.rounded)}
                    aria-label="remove"
                    onClick={() => handleRemovePax()}
                    disabled={selectedPax <= 1}
                  >
                    <RemoveIcon />
                  </Fab>
                </div>
                <div className="col-xs-4 text-center">
                  <p className={classes.paxSelector}>{selectedPax}</p>
                </div>
                <div className="col-xs-4 text-center">
                  <Fab
                    className={clsx(classes.primaryColor, classes.rounded)}
                    aria-label="add"
                    onClick={() => handleAddPax()}
                    disabled={wantToSelectMorePax}
                  >
                    <AddIcon />
                  </Fab>
                </div>
              </div>

              {stepsVoucherCreationParams.step1.productInfo?.accept_kids && (
                <React.Fragment>
                  <p>
                    {I18n.t("kids")} (
                    {to_eur(
                      stepsVoucherCreationParams.step1.productInfo?.pvpKids
                    ).toFixed(2)}
                    €)
                  </p>
                  <div className="row">
                    <div className="col-xs-4 text-center">
                      <Fab
                        className={clsx(classes.primaryColor, classes.rounded)}
                        aria-label="remove"
                        onClick={handleRemovePaxKids}
                        disabled={selectedPaxKids <= 0}
                      >
                        <RemoveIcon />
                      </Fab>
                    </div>
                    <div className="col-xs-4 text-center">
                      <p className={classes.paxSelector}>{selectedPaxKids}</p>
                    </div>
                    <div className="col-xs-4 text-center">
                      <Fab
                        className={clsx(classes.primaryColor, classes.rounded)}
                        aria-label="add"
                        onClick={handleAddPaxKids}
                        disabled={wantToSelectMorePax}
                      >
                        <AddIcon />
                      </Fab>
                    </div>
                  </div>
                </React.Fragment>
              )}

              <div className="col-xs-12">
                <hr />
              </div>
              <div className="col-xs-6">
                <p className={classes.totalText}>Total</p>
              </div>
              <div className="col-xs-6 text-right">
                <p className={classes.price}>{totalPrice()}€</p>
              </div>

              <div className="row" style={{ marginBottom: "0px", bottom: 0 }}>
                <div className="col-xs-12">
                  {wantToSelectMorePax && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: I18n.t("book_group_html", {
                          hotel:
                            stepsVoucherCreationParams.step1.productInfo?.hotel,
                          experience:
                            stepsVoucherCreationParams.step1.productInfo?.name,
                        }),
                      }}
                    ></p>
                  )}

                  <br />
                </div>
              </div>
            </React.Fragment>
          )}

          <Button
            className={clsx(
              "btn btn-primary",
              props.mobile && classes.fullWidth
            )}
            style={{ marginTop: "20px" }}
            onClick={() => onSubmit()}
          >
            {I18n.t("new_gift__select_photo")}
          </Button>
        </form>
      )}
    </div>
  );
}

export default ProductForm;
