import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useSelector, useDispatch } from "react-redux";
import { changeSelectedArea } from "../../redux/actions/actions";
import slugify from "react-slugify";
import { makeStyles } from "@material-ui/core/styles";
import HotelBreakLoader from "../components/loaders/hotelBreakLoader";
import I18n from "i18n-js";
import AreaSelectortextfieldStyled from "../stylesUI/AreaSelectortextfieldStyled";

function AreaNav(props) {
  const dispatch = useDispatch();
  const csrfToken = useSelector((state) => state.csrfToken);
  const currentLocale = useSelector((state) => state.currentLocale);
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;

  const [areas, setAreas] = useState(false);
  const [topAreas, setTopAreas] = useState(false);
  const [otherAreas, setOtherAreas] = useState(false);
  const [searchArea, setSearchArea] = useState("");

  const onSearchArea = (event) => {
    setSearchArea(event.target.value);
  };

  const useStyles = makeStyles((theme) => ({
    areaNav: {
      width: "390px;",
    },
    textField: {
      width: "100%",
    },
  }));

  const classes = useStyles();

  const onAreaSelect = (area) => {
    axios
      .post("/api/main/areas/cookie.json", { area: slugify(area) })
      .then((response) => {
        const redirect_to_area = response.status === 200 && area !== null;
        if (redirect_to_area) {
          location.href =
            location.origin + response.data.redirect_to + currentLocale + "/" + slugify(area);
        }
      });
  };

  const searchFilter = () => {
    return areas.filter((str) => {
      return str.toLowerCase().indexOf(searchArea.toLowerCase()) >= 0;
    });
  };

  useEffect(() => {
    axios.get("/api/main/areas/index.json").then((response) => {
      setAreas(response.data.areas);
      setTopAreas(response.data.top_areas);
      setOtherAreas(response.data.other_areas);
    });
  }, []);

  var loadedAreas = topAreas && otherAreas;

  return (
    JSON.stringify(areas) !== undefined && (
      <div className={classes.areaNav} style={{ width: "390px !important" }}>
        <DialogContent>
          {loadedAreas && (
            <AreaSelectortextfieldStyled onSearchArea={onSearchArea} />
          )}
          {!loadedAreas && <HotelBreakLoader />}

          {loadedAreas && <hr></hr>}
          <List component="nav" aria-label="secondary mailbox folders">
            {searchArea.length == 0 && loadedAreas && (
              <DialogContentText>{I18n.t("top_cities")}</DialogContentText>
            )}
            {searchArea.length == 0 &&
              loadedAreas &&
              topAreas.map((area) => {
                return (
                  <ListItem onClick={() => onAreaSelect(area)} button>
                    <ListItemText primary={area} />
                  </ListItem>
                );
              })}
            <br></br>
            {searchArea.length == 0 && loadedAreas && (
              <DialogContentText>{I18n.t("other_cities")}</DialogContentText>
            )}
            {searchArea.length == 0 &&
              loadedAreas &&
              otherAreas.map((area) => {
                return (
                  <ListItem onClick={() => onAreaSelect(area)} button>
                    <ListItemText primary={area} />
                  </ListItem>
                );
              })}

            {searchArea.length != 0 &&
              loadedAreas &&
              searchFilter().length != 0 &&
              areas &&
              searchFilter().map((area) => {
                return (
                  <ListItem onClick={() => onAreaSelect(area)} button>
                    <ListItemText primary={area} />
                  </ListItem>
                );
              })}

            {searchArea.length != 0 &&
              searchFilter().length == 0 &&
              "No results were found :("}
          </List>
        </DialogContent>
      </div>
    )
  );
}

export default AreaNav;
