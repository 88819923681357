import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import I18n from "../../i18n-js/index.js.erb";
import { useSelector, useDispatch } from "react-redux";
import { setPaymentData } from "../redux/actions/actions";
import { useStylesPayment } from "./styles/paymentOptionsStyles";
import ReservationUserFormFunnel from "./components/users/ReservationUserFormFunnel";

const reservation_code = new URLSearchParams(window.location.search).get(
  "reservation_code"
);

function UserStep(props) {
  const dispatch = useDispatch();
  const classes = useStylesPayment();
  const FunnelData = useSelector((state) => state.PaymentData);
  const currentLocale = useSelector((state) => state.currentLocale);
  const csrfToken = useSelector((state) => state.csrfToken);

  I18n.locale = currentLocale || props.locale;

  const [paymentLoad, setPaymentLoad] = useState(false);

  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <br />
      <div className="col-md-7 checkout_column">
        <h4>
          <span className="number_step">1</span>&nbsp;&nbsp;{" "}
          {I18n.t("checkout_first_step")}
        </h4>

        <ReservationUserFormFunnel {...props} />
      </div>

      {false && (
        <form onSubmit={handleSubmit}>
          <div className="col-md-7 checkout_column">
            <h4>
              <span className="number_step">1</span>&nbsp;&nbsp;{" "}
              {I18n.t("checkout_first_step")}
            </h4>
          </div>
        </form>
      )}
    </React.Fragment>
  );
}

export default UserStep;
